import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

import { notesReducer } from './notesReducer';
import authReducer from './authReducer';
import { INote, IUtility } from '../../data-models';
import { UserAction } from '../constants';
import { utility } from './utilityReducer';

//needs to match combineReducers
export interface IAppState {
  notesStore: INotesLocalStore;
  authStore: { authError: string };
  firebase: {
    auth: {
      isLoaded: boolean | undefined;
      uid: string;
      email: string;
    };
  };
  firestore: {
    ordered: { notes: any };
    data: { notes: any }
  };
  utility: IUtility;
}

export interface ICurrentNoteStatus {
  noteId: string;
  hasChanges: boolean;
  isLoading: boolean;
  noteExistsInDB: boolean;
}

export interface INotesObj {
  //notes object from firestore with unique note id's as keys and the note obj as the value
  [key: string]: INote;
}

interface IUserNotes {
  notes: INotesObj;
  isLoading: boolean;
}

//applies to notesReducer
export interface INotesLocalStore {
  redirect: boolean;
  //FUTURE: at scale, shouldn't grab all user notes immediately
  //User notes that have been downloaded to local storage reducers
  userNotes: IUserNotes;
  defaultNotes: INotesObj;
  currentNoteStatus: ICurrentNoteStatus;
}

interface IUser {
  id?: string;
  authId?: string;
  name?: string;
  email?: string;
}

interface IUserPreferenceStore {
  isLoadingUserPreferences: boolean;
  isUpdatingUserPreferences: boolean;
  // newPreferences: IUserPreferences;
  // userPreferences?: UserPreferences;
}

interface authReducer {}

//need to match IAppState types
const appReducer = combineReducers({
  utility: utility,
  notesStore: notesReducer,
  authStore: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
});

export default (state: any, action:any) => {
  //clear redux state on sign out to prevent unauthorized access to cached data (in case browser not refreshed when 2nd user signed into shared browser)
  if(action.type === UserAction.signOutSuccess) {
    state = undefined;
  }
  return appReducer(state, action);
}
