import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { INote } from '../../data-models';
import { createNoteTemplate } from '../../templates';
import newNoteIcon from '../Sidebar/newNoteIcon.svg';

interface IProps {
  onNewNoteClick: any;
}

const EmptySelectionPage = (props: IProps) => {
  const { onNewNoteClick } = props;
  return (
    <div css={containerStyles}>
      <h1>Welcome to WingNotes</h1>
      <p>Select or create a new note!</p>
      <button onClick={onNewNoteClick} css={buttonStyles}>
        <img src={newNoteIcon} alt="newNoteIcon" width="18" height="18" />
        New Note
      </button>
    </div>
  );
};

export { EmptySelectionPage };

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35vh;
  width: 100%;
  p {
    font-size: 20px;
    color: grey;
  }
`;

const buttonStyles = css`
  justify-content: center;
  cursor: pointer;
  display: flex;
  padding: 6px 8px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  color: #5d5d5d;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgba(10, 16, 34, 0.2);
  transition: all 0.1s ease;
  outline: none;
  &:hover {
    transform: scale(1.05);
  }
  img {
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
`;
