import * as React from 'react';
import { AlignedEditorContext } from '../../Contexts/AlignedEditorContext';
import TextEditor from './TextEditor';

interface IProps {
  sectionId: string;
  leftOrRight: 'left' | 'right' | 'both';
  editorData: any;
}

export default (props: IProps) => (
  <AlignedEditorContext.Consumer>
    {({ updateEditorData, readOnly }) => (
      <TextEditor
        {...props}
        updateEditorData={updateEditorData}
        readOnly={readOnly}
      />
    )}
  </AlignedEditorContext.Consumer>
);
