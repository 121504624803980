import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Layout } from './Layout';
import { styled } from '../common/ThemedStyledComponent';

interface IProps {}

const About = ({  }: IProps) => {
  return (
    <Layout>
      <PageContainer>
        <div css={sectionStyles}>
          <div css={max1100ContainerStyles}>
            <div css={textContainerStyles}>
              <h1>ABOUT</h1>
              <p>
                WingNotes was built to help keep notes organized and readable,
                yet easy to use.
              </p>
              <p>
                It specifically shines in organizing{' '}
                <a href="/about/#referential-notes">
                  <i>referential-notes</i>
                </a>{' '}
                — ie. notes that follow a description-and-reference structure —
                such as those used for studying or documenting.
              </p>
            </div>
          </div>
        </div>
        <div className="faq" css={sectionStyles}>
          <div css={max1100ContainerStyles}>
            <div className="faqText" css={textContainerStyles}>
              <h1>FREQUENTLY ASKED QUESTIONS</h1>
              <h3>What's the problem?</h3>
              <p>
                Nearly all note-taking apps are single-column, which makes
                taking notes that rely on references (think images, videos, code
                snippets) cumbersome to review.
              </p>
              <p>
                Say you're taking lecture notes, which switch between
                explanations and examples: you'll quickly find yourself
                scrolling up and down to review what was explained via text and
                what was referenced via media (ex. code).
              </p>
              <p>
                To solve this, WingNotes uses a layout designed for{' '}
                <i>referential note-taking</i>.
              </p>
              <br />
              <h3 id="referential-notes">What is referential note-taking?</h3>
              <p>
                Referential note-taking is a note-taking method that organizes
                ones notes by clearly separating the descriptions (ex. text)
                from the references (ex. images, videos, code snippets, etc.).
              </p>
              <p>
                Loosely inspired by{' '}
                <a href="https://en.wikipedia.org/wiki/Cornell_Notes">
                  Cornell Notes
                </a>{' '}
                but distinctly different, referential note-taking was designed
                for the modern age and takes advantage of the responsiveness of
                digital layouts to keep things nice, tidy, and simple.
              </p>
              <p>
                A key component of a referential note's layout is the fact that
                the descriptions and references of a particular section will
                always remain horizontally aligned (given enough screen space).
              </p>
              <p>
                When reading back your notes, the content and examples will be
                visible in-line, saving you from needing to scroll up and down.
              </p>
              <br />
              <h3>Who created this and how is it free?</h3>
              <p>
                WingNotes is a side project by{' '}
                <a href="https://brandontle.com/">Brandon Le</a>. He's been
                using a private version of WingNotes for some time now, and
                recently decided to host it using Firebase's generous free tier
                for anyone to use.
              </p>
              <p>
                There are currently no plans to offer a premium version with
                more features, although if there's enough interest, it could
                happen. If that sounds like something you're interested in,
                please leave some <a href="/feedback">feedback</a>!
              </p>
              <br />
              <h3>Why is there no auto-save?</h3>
              <p>Short answer: because WingNotes is currently free.</p>
              <p>
                Longer answer: auto-save is one of the most expensive features
                to provide because the database charges for every request (ie. save). And
                everytime you make a change to a note (even with a delay, often
                called debounce), a request is made. So you can imagine just how
                quickly the bill can run up when users edit their notes. A manual saving mechanism allows me to keep the bill low, while providing WingNotes to as many people as possible for free.
              </p>
              <p>
                If you're interested in a premium version with auto-save, feel
                free to reach out via the <a href="/feedback">feedback page</a>!
              </p>
              <br />
              <h3>
                I was one of the few who used the Alpha version. Does it still
                exist?
              </h3>
              <p>
                If you were one of the very few people who tried out the Alpha
                version of WingNotes, I thank you for your valuable feedback!
                You can still access the site here:{' '}
                <a href="https://alpha.wingnotes.app">alpha.wingnotes.app</a>,
                although the two databases are completely separate and the Alpha
                version is no longer maintained.
              </p>
            </div>
          </div>
        </div>
        <div css={sectionStyles}>
          <div css={max1100ContainerStyles}>
            <div css={textContainerStyles}>
              <h1>KNOWN ISSUES</h1>
              <p>
                WingNotes is a continual work in progress. There are a few known
                issues that will be worked on:
                <ul>
                  <li>
                    To upload images or embed videos, users currently must type
                    an arbitrary character, highlight it, and select the action.
                    This is due to the Quill plugin used, which is in the
                    process of being customized.
                  </li>
                  <li>
                    Formatting and styling issues with edits: for example, if
                    users change one text style say code block) into another
                    (say, bullets), styling such as text color will carry over.{' '}
                  </li>
                  <li>Links are currently not clickable.</li>
                  <li>Firefox and Safari support are spotty.</li>
                </ul>
                <p>
                  Any additional issues, requests, or comments are more than
                  welcome via the <a href="/feedback">feedback page</a>!
                </p>
              </p>
            </div>
          </div>
        </div>
      </PageContainer>
    </Layout>
  );
};

export { About };

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 5vh auto 0;
  .faq {
    background: #151417;
    margin: 10vh 0 5vh;
    color: white;
    h1 {
      /* font-size: 48px; */
    }
    h3 {
      font-size: 30px;
    }
    p {
      color: grey;
      a {
        color: white;
        font-weight: 400;
        text-decoration: none;
        padding-bottom: 3px;
        /* border-bottom: 1px solid blue; */
      }
      a:visited {
        color: white;
      }
      a:hover {
        padding-bottom: 2px;
        border-bottom: 1px solid white;
      }
    }
  }
`;

const max1100ContainerStyles = css`
  max-width: 1100px;
  margin: 0 auto;
  padding: 0px 20px;
  .faqText {
    margin: 0 auto;
  }
`;

const sectionStyles = css`
  width: 100%;
  margin: 10vh 0 15vh;
  padding: 15vh 0 15vh;
`;

const textContainerStyles = css`
  max-width: 600px;
  h1 {
    font-size: 24px;
    font-family: 'Roboto Mono', 'Andale Mono', monospace;
    letter-spacing: 3px;
    padding-bottom: 8px;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    color: grey;
    a {
      color: black;
      font-weight: 400;
      text-decoration: none;
      /* border-bottom: 1px solid blue; */
    }
    a:visited {
      color: black;
    }
    a:hover {
      padding-bottom: 2px;
      border-bottom: 1px solid black;
    }
  }
`;
