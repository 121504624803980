import React, { Component, Fragment } from 'react';
import { styled } from '../common/ThemedStyledComponent';
import { NavLink } from 'react-router-dom';
import { INote } from '../../data-models';

import * as Icon from 'react-feather';
import newNoteIcon from './newNoteIcon.svg';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import producer from 'immer';
import { CenteredModal } from '../common/CenteredModal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ContentLoader from 'react-content-loader';
import { FeedbackModal } from '../common/FeedbackModal';
import { htmlToMarkdown } from '../../helpers/exporter';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';

interface IProps {
  orderLabel: string;
  ascOrDesc: string;
  notes: INote[];
  category: string;
  toggleOrderLabel: any;
  toggleAscOrDesc: any;
  updateSidebarCategory: any;
}

export const NotesList = (props: IProps) => {
  const {
    notes,
    category,
    ascOrDesc,
    orderLabel,
    updateSidebarCategory,
    toggleOrderLabel,
    toggleAscOrDesc,
  } = props;
  const orderedNotes = orderBy(notes, orderLabel, ascOrDesc as any);

  const renderDay = (note: INote) => {
    if (orderLabel === 'updatedAt') {
      return `${moment(note.updatedAt.toMillis()).format('MMM. Do, YYYY')}`;
    } else if (orderLabel === 'createdAt') {
      return `${moment(note.createdAt.toMillis()).format('MMM. Do, YYYY')}`;
    }
  };

  const renderTime = (note: INote) => {
    if (orderLabel === 'updatedAt') {
      return `${moment(note.updatedAt.toMillis()).format('HH:mm')}`;
    } else if (orderLabel === 'createdAt') {
      return `${moment(note.createdAt.toMillis()).format('HH:mm')}`;
    }
  };

  const renderPreview = (note: INote) => {
    const firstSectionId = note.editorState.sectionsOrder[0];
    const firstSection = note.editorState.sections[firstSectionId];
    return htmlToMarkdown(firstSection.left.html);
  };

  return (
    <Fragment>
      <NoteOrder>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id={`tooltip-ascOrDesc`}>
              {ascOrDesc === 'asc' ? 'Oldest first' : 'Most recent first'}
            </Tooltip>
          }
        >
          <Button
            //@ts-ignore
            variant="transparent"
            onClick={toggleAscOrDesc}
            onMouseDown={(e: any) => e.preventDefault()}
          >
            {ascOrDesc === 'asc' ? (
              <Icon.ChevronDown size={14} style={{ marginTop: '.1em' }} />
            ) : (
              <Icon.ChevronUp size={14} style={{ marginTop: '.1em' }} />
            )}
          </Button>
        </OverlayTrigger>
        <Button
          //@ts-ignore
          variant="transparent"
          onClick={toggleOrderLabel}
          onMouseDown={(e: any) => e.preventDefault()}
          style={{ fontSize: '11px' }}
        >
          {orderLabel === 'updatedAt' ? 'Updated At' : 'Created At'}
        </Button>
      </NoteOrder>
      <NoteItemList>
        {orderedNotes &&
          orderedNotes.map((note, i) => {
            if (!note.editorState) {
              return;
            }
            const firstLeftSectionId = note.editorState.sectionsOrder[0];
            const leftModule = note.editorState.sections[firstLeftSectionId];
            if (
              category === 'All' &&
              !note.inTrash &&
              !note.default &&
              //FUTURE: fix why 'welcome' note still exists after new user sign up
              note.noteId !== 'welcome' &&
              note.noteId !== 'algorithmsExample' &&
              note.noteId !== 'exampleNote2' &&
              note.noteId !== 'homeNote' &&
              note.noteId !== 'default'
            ) {
              return (
                <NoteItem to={`/notes/${note.noteId}`} key={i}>
                  <NoteItemDescription type="title">
                    {note.title}
                  </NoteItemDescription>
                  <NoteItemDescription>
                    {/* {leftModule.leftData.blocks[0].data} */}
                    {renderPreview(note)}
                  </NoteItemDescription>
                  <div css={timestampStyles}>
                    <span>{renderDay(note)}</span>
                    <span>{renderTime(note)}</span>
                  </div>
                  {/* <NoteItemDescription>{renderDate(note)}</NoteItemDescription> */}
                </NoteItem>
              );
            } else if (
              category === 'Trash' &&
              note.inTrash &&
              !note.default &&
              //FUTURE: fix why 'welcome' note still exists after new user sign up
              note.noteId !== 'welcome' &&
              note.noteId !== 'algorithmsExample' &&
              note.noteId !== 'firestoreExample' &&
              note.noteId !== 'homeNote' &&
              note.noteId !== 'default'
            ) {
              return (
                <NoteItem to={`/notes/${note.noteId}`} key={i}>
                  <NoteItemDescription type="title">
                    {note.title}
                  </NoteItemDescription>
                  <NoteItemDescription>
                    {/* {leftModule.leftData.blocks[0].data} */}
                  </NoteItemDescription>
                  <NoteItemDescription>
                    {/* {moment(note.updatedAt.toMillis()).format('MMM. Do, YYYY')} */}
                  </NoteItemDescription>
                </NoteItem>
              );
            }
          })}
      </NoteItemList>
    </Fragment>
  );
};

const NoteOrder = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
`;

const NoteItemList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  border-top: solid 1px #c4c4c4;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
  &:hover {
    ::-webkit-scrollbar {
      width: 6px;
      background: unset; /* make scrollbar visible */
    }
  }
`;

const NoteItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  height: fit-content;
  padding: 15px 10px 10px;
  box-sizing: border-box;
  /* width: 205px; */
  border-bottom: solid 1px #c4c4c4;
  color: #000;
  text-overflow: ellipsis;
  &.active {
    color: #000;
    background-color: rgba(233, 230, 237, 1);
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 0.6);
  }
`;

interface INoteItemDescription {
  type?: string;
}

const NoteItemDescription = styled.div<INoteItemDescription>`
  color: ${props => (props.type === 'title' ? 'inherit' : '#8c8c8c')};
  font-size: ${props => (props.type === 'title' ? 'inherit' : '12px')};
  font-weight: ${props => (props.type === 'title' ? '500' : 'unset')};
  overflow: hidden;
  text-overflow: ellipsis;
  //to be able to clip the excerpt and add the ellipsis if overflow
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: ${props => (props.type === 'title' ? '18px' : '16px')};
  //max hieght is 2x line height
  max-height: ${props => (props.type === 'title' ? '36px' : '32px')};
  min-height: ${props => (props.type === 'title' ? '18px' : '16px')};
  margin-bottom: ${props => (props.type === 'title' ? '6px' : '5px')};
`;

const timestampStyles = css`
  display: flex;
  justify-content: space-between;
  color: #8c8c8c;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  max-height: 32px;
  min-height: 16px;
  margin-bottom: 5px;
`;
