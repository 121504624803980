import { connect } from 'react-redux';
import { compose, Dispatch, bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IAppState, INotesObj } from '../../store/reducers';
import { firestoreConnect } from 'react-redux-firebase';
import { Note } from './Note';
import { displayNote, saveCurrentNote, updateTitleInStore, restoreNoteFromTrash, permDeleteNote, downloadNNotes, updateEditorStateInStore, createNote } from '../../store/actions';
import { INote } from '../../data-models';
import { NetworkProvider } from '../../store/NetworkProvider';
import { History } from 'history';
import { IEditorState } from '../AlignedEditor';

interface IStateToProps {
  urlNoteId: string;
  history: History;
  currentNote: INote;
  noteExistsinDB: boolean;
  isCurrentNoteLoading: boolean;
  hasChanges: boolean;
  userNotes: INotesObj;
  redirect: boolean;
  defaultNotes: INotesObj;
  email: string;
}

interface IDispatchToProps {
  createNote(newNote: INote): string;
  displayNote(noteId: string):any;
  saveCurrentNote(noteId: string):void;
  updateTitleInStore(urlNoteId: string, title:string):void;
  restoreNoteFromTrash(noteId: string): void;
  permDeleteNote(noteId:string): void;
  downloadNNotes(num: number): void;
  updateEditorStateInStore(noteId: string, editorState: IEditorState): void;
}

interface IOwnProps extends RouteComponentProps<MatchParams> {
  history: History
}

interface MatchParams {
  id: string;
}

export interface INoteProps extends IStateToProps, IDispatchToProps {
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps): IStateToProps => {
  const urlNoteId = ownProps.match.params.id
  return {
    urlNoteId,
    history: ownProps.history,
    isCurrentNoteLoading: state.notesStore.currentNoteStatus.isLoading,
    hasChanges: state.notesStore.currentNoteStatus.hasChanges,
    currentNote: state.notesStore.userNotes.notes[urlNoteId],
    noteExistsinDB: state.notesStore.currentNoteStatus.noteExistsInDB,
    redirect: state.notesStore.redirect,
    userNotes: state.notesStore.userNotes.notes,
    defaultNotes: state.notesStore.defaultNotes,
    email: state.firebase.auth.email,
  };
};

//TO FIX: ':any' typing of mapDispatchToProps
const mapDispatchToProps = (dispatch: Dispatch<any>): any => {
  return {
    createNote: bindActionCreators(createNote, dispatch),
    displayNote: bindActionCreators(displayNote, dispatch),
    updateEditorStateInStore: bindActionCreators(updateEditorStateInStore, dispatch),
    saveCurrentNote: bindActionCreators(saveCurrentNote, dispatch),
    updateTitleInStore: bindActionCreators(updateTitleInStore, dispatch),
    restoreNoteFromTrash: bindActionCreators(restoreNoteFromTrash, dispatch),
    permDeleteNote: bindActionCreators(permDeleteNote, dispatch),
    downloadNNotes: bindActionCreators(downloadNNotes, dispatch),
  }
}

export const NoteContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Note) as any;
