import * as React from 'react';
import { useState, useEffect, useRef, PureComponent } from 'react';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import SectionsList from './SectionsList';
import { ISection, ISections, ISideData } from '../../data-models';
import { AlignedEditorProvider } from './Contexts/AlignedEditorContext';
import arrayMove from 'array-move';

export interface IEditorJsData {
  time: number;
  blocks: {
    type: string;
    data: object;
  }[];
  version: string;
}

export interface IEditorState {
  sectionsOrder: string[];
  sections: ISections;
}

interface IState {
  editorState: IEditorState;
  readOnly?: boolean;
  collapsed: boolean;
}

interface IProps {
  editorState: IEditorState;
  readOnly?: boolean;
  onChange: (updatedEditorState: IEditorState) => any;
  demo?: boolean;
}

class AlignedEditor extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { editorState, onChange, readOnly } = this.props;
    this.state = {
      editorState,
      readOnly,
      // firstUpdate: true,
      collapsed: false,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const { onChange } = this.props;
    const { editorState: prevEditorState } = prevProps;
    const { editorState } = this.state;
    // const { sections, sectionsOrder } = this.state.editorState;
    if (prevEditorState !== editorState) {
      onChange(this.state.editorState);
    }
    if(this.props.demo && prevEditorState !== this.props.editorState) {
      this.setState({
        editorState: this.props.editorState
      })
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    // console.log('unmount');
    this.setState({
      editorState: { sections: {}, sectionsOrder: [] },
    });
  }

  // ----- SECTION METHODS -----
  private updateSectionOrder = (oldIndex: number, newIndex: number) => {
    const { sectionsOrder } = this.state.editorState;
    const updatedSectionsOrder = arrayMove(sectionsOrder, oldIndex, newIndex);
    this.setState({
      editorState: {
        sections: this.state.editorState.sections,
        sectionsOrder: updatedSectionsOrder,
      },
    });
  };

  private insertSection = (sectionIndex: number, sectionToInsert: ISection) => {
    const { sections, sectionsOrder } = this.state.editorState;
    const updatedSectionsOrder = Object.assign([], sectionsOrder);
    const updatedSections = Object.assign({}, sections);

    // update `sectionsOrder`
    const indexToInsert = sectionIndex + 1;
    const newSectionId = (sectionToInsert as ISection).sectionId;
    updatedSectionsOrder.splice(indexToInsert, 0, newSectionId);

    // update `sections`
    // updatedSections[newSectionId] = sectionToInsert;
    updatedSections[newSectionId] = sectionToInsert;
    this.setState({
      editorState: {
        sections: updatedSections,
        sectionsOrder: updatedSectionsOrder,
      },
    });
    // sectionsOrder.current = updatedSectionsOrder;
  };

  private deleteSection = (sectionId: string) => {
    const { sections, sectionsOrder } = this.state.editorState;
    const updatedSectionsOrder = Object.assign([], sectionsOrder);
    const updatedSections = Object.assign({}, sections);

    // delete sectionId in `sectionsOrder`
    const indexToDelete = updatedSectionsOrder.indexOf(sectionId);
    updatedSectionsOrder.splice(indexToDelete, 1);

    // delete section
    delete updatedSections[sectionId];

    this.setState({
      editorState: {
        sections: updatedSections,
        sectionsOrder: updatedSectionsOrder,
      },
    });
  };

  private updateEditorData = (
    sectionId: string,
    leftOrRight: 'left' | 'right' | 'both',
    updatedData: ISideData
  ) => {
    // dont assign new object because don't want to trigger re-render with each update
    const { sections, sectionsOrder } = this.state.editorState;

    const updatedSections: ISections = sections;

    if (leftOrRight === 'left') {
      updatedSections[sectionId].left.html = updatedData.html;
    } else if (leftOrRight === 'right') {
      updatedSections[sectionId].right.html = updatedData.html;
    }

    this.setState({
      editorState: {
        sections: updatedSections,
        sectionsOrder: this.state.editorState.sectionsOrder,
      },
    });
  };

  render() {
    const { collapsed, readOnly, editorState } = this.state;
    const { sections, sectionsOrder } = editorState;
    return (
      <AlignedEditorProvider
        sections={sections}
        sectionsOrder={sectionsOrder}
        updateSectionOrder={this.updateSectionOrder}
        insertSection={this.insertSection}
        deleteSection={this.deleteSection}
        updateEditorData={this.updateEditorData}
        collapsed={collapsed}
        readOnly={readOnly}
      >
        <div css={alignedEditorContainerStyles}>
          {/* <button
          onClick={() => setCollapsed(!collapsed)}
          onMouseDown={(e: any) => e.preventDefault()}
          css={CollapseButtonStyles}
        >
          {collapsed ? 'Expand All' : 'Collapse All'}
        </button> */}
          <SectionsList
            sections={sections}
            sectionsOrder={sectionsOrder}
            updateSectionOrder={this.updateSectionOrder}
          />
        </div>
      </AlignedEditorProvider>
    );
  }
}

export { AlignedEditor };

const alignedEditorContainerStyles = css`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
  /* max-width: 1200px; */
  max-height: fit-content;
  /* margin: 12vh auto 0; */
  /* padding: 0 4% 0 calc(4% + 1.1em); */
`;

const CollapseButtonStyles = css`
  cursor: pointer;
  border: none;
  font-size: 14px;
  background: transparent;
  padding: 5px 8px;
  border-radius: 4px;
  align-self: flex-end;
  width: fit-content;
  &:hover {
    background: #eaeaea;
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 1);
    box-shadow: none;
  }
`;
