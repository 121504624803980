import React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
//@ts-ignore
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { Layout } from './Layout';
import { styled } from '../common/ThemedStyledComponent';

interface IProps {}

const Feedback = ({  }: IProps) => {
  return (
    <Layout>
      <HeroContainer>
        <ReactTypeformEmbed url="https://brandonle.typeform.com/to/lkUenq" style={{top: '51px'}}/>
      </HeroContainer>
    </Layout>
  );
};

export { Feedback };

const HeroContainer = styled.div`
  max-width: 1100px;
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 50px auto;
`;
