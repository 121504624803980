import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { INote } from '../data-models';
import { markdownCheatsheetImg } from './longData';

const firestore = firebase.firestore;

export const homeNote: INote = {
  noteId: 'homeNote',
  ownerIds: [''],
  title: 'Home Note',
  inTrash: false,
  default: true,
  createdAt: firestore.Timestamp.fromDate(new Date('2010-04-10T03:24:00')),
  updatedAt: firestore.Timestamp.fromDate(new Date('2010-04-10T03:24:00')),
  editorState: {
    sectionsOrder: [
      'section-1',
      'section-2',
      'section-3',
      'section-4',
      'section-5',
    ],
    sections: {
      'section-1': {
        sectionId: 'section-1',
        left: {
          html: `<h2>Structured sections</h2>
          <p>Each note consists of <i>draggable</i> <strong>sections</strong>, and each section consists of a left column for <strong>descriptions</strong>, and a right column for <strong>references</strong>.</p>
          <br/>
          <p>Content within each section always stay aligned. That way, your references will remain relevant with each description.</p>
          <br/>
          <p>Try hovering in between sections to add a new section.</p>`,
        },
        right: {
          html: `<pre class='ql-syntax' spellcheck='false'>const insertCode = (code) => {\n  console.log('All of your code examples go here, like this!', code);\n};</pre><br/>`,
        },
      },
      'section-2': {
        sectionId: 'section-2',
        left: {
          html: `<h2>Markdown-ready</h2>
          <p>WingNotes is equipped with markdown support. For example, a few features you can use are:<ul>
              <li>Headers (#)</li>
              <li>Bullet points (*)</li>
              <li>Ordered lists (1.)</li>
              <li>Code blocks (\`\`\`)</li>
            </ul>
          </p>
          <br/>
          <p>Or check out the cheatsheet image on the right to see what you can do!</p>
          <br/>
          <p>And if typing markdown isn't your thing, you can also highlight texts to change their styles!</p>`,
        },
        right: {
          html: markdownCheatsheetImg,
        },
      },
      'section-3': {
        sectionId: 'section-3',
        left: {
          html: `<h2>Embedded media</h2>
          <p>WingNotes currently supports a few embedded media types, including:</p>
          <ul>
            <li>Videos, ex. from Youtube</li>
            <li>Uploadable images</li>
          </ul>
          <br/>
          <p>Currently, to use these features, you must first type an arbitrary character, highlight it, and select the option from the pop-up toolbar (I know). This will change to something more intuitive in the future.</p>
          `,
        },
        right: {
          html: `<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/mxK8b99iJTg?showinfo=0"></iframe>`,
        },
      },
      'section-4': {
        sectionId: 'section-4',
        left: {
          html: `<h2>Responsive Layout</h2>
          <p>Although primarily designed for the desktop browser, WingNotes is (for the most part) mobile friendly. However, due to the limited screen space, the references will appear below their respective sections just like a normal note-taking app.</p>`,
        },
        right: {
          html: ` `,
        },
      },
      'section-5': {
        sectionId: 'section-5',
        left: {
          html: `<h2>Exportable data</h2>
          <p>Last but not least, export your data at any time. Download all of your notes as standard markdown files!</p>`,
        },
        right: {
          html: ``,
        },
      },
    },
  },
};

// sections: {
//     'section-1': {
//       id: 'section-1',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `# Structured sections
//             \nEach note consists of **sections**, and each section consists of a **description** on the left, and **reference blocks** on the right.
//             \nTry editing and playing around!`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: ''
//           }
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'code',
//           payloadObj: {
//             code: `const insertCode = (code) => {\n  console.log('All of your code examples go here, like this!', code);\n};`,
//             settings: {
//               languageLabel: 'Javascript',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: ''
//           }
//         },
//       },
//     },
//     'section-2': {
//       id: 'section-2',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-2'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `# Markdown-ready
//             \nWingNotes is equipped with full [markdown](https://en.wikipedia.org/wiki/Markdown) support. Check out the cheatsheet on the right to see what you can do!
//             \nAnd if typing markdown isn't your thing, you can also highlight texts to change their styles!`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: ''
//           }
//         },
//       },
//       rightModules: {
//         // 'module-1': {
//         //   id: 'module-1',
//         //   type: 'link',
//         //   payloadObj: {
//         //     link: `https://en.wikipedia.org/wiki/Markdown`,
//         //     linkType: `Preview`,
//         //   },
//         //   moduleAddress: {
//         //     noteId: '',
//         //     sectionId: '',
//         //     rightModulesId: ''
//         //   }
//         // },
//         'module-2': {
//           id: 'module-2',
//           type: 'link',
//           payloadObj: {
//             link: `https://i.imgur.com/SH9NnSz.png`,
//             linkType: `Image`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: ''
//           }
//         },
//       },
//     },
//     'section-3': {
//       id: 'section-3',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1', 'module-2'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `# Reference blocks
//             \nTo help keep your notes tidy, add **reference blocks** on the right of each relevant section. Now, you no longer have to scroll up and down to follow descriptions and examples!
//             \n Currently, you can add any of the following as reference blocks:
//             \n* Code
//             \n* Image links
//             \n* Embedded video and audio links (ex. Youtube, Soundcloud)
//             \n* Link previews\n\n\nFor example, try adding a block, or submitting the image link to the right! ➡️
//             `,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: ''
//           }
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'link',
//           payloadObj: {
//             link: `https://wingnotes.co`,
//             linkType: `Preview`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//         'module-2': {
//           id: 'module-2',
//           type: 'link',
//           payloadObj: {
//             link: `https://i.imgur.com/NXZbSkU.jpg`,
//             linkType: ``,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-4': {
//       id: 'section-4',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: [],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `# Rearrangeable sections and blocks
//             \nEach section and reference block is draggable — just use the arrow icon to the left side of each section and reference block (appears on hover).`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: ''
//           }
//         },
//       },
//       rightModules: {
//       },
//     },
//     'section-5': {
//       id: 'section-5',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: [],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `# Responsive layout
//             \nAlthough this app was made primarily for use on the desktop, this site is (for the most part) mobile friendly. However, due to the limited screen space, the reference blocks will appear below their respective sections normal.`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: ''
//           }
//         },
//       },
//       rightModules: {
//       },
//     },
//   }
