import React, { Component, Fragment } from 'react';
import { styled } from '../common/ThemedStyledComponent';
import { Prompt } from 'react-router';
import ReactGA from 'react-ga';
import orderBy from 'lodash/orderBy';

import { INoteProps as IProps } from './NoteContainer';
import { ToolbarContainer } from './Toolbar/ToolbarContainer';
import { WarningBar } from './Toolbar/WarningBar';
import { AlignedEditor, IEditorState } from '../AlignedEditor';
import { EmptySelectionPage } from './EmptySelectionPage';
import { NoteLoader } from './NoteLoader';
import { NoteHeader } from './NoteHeader';
import { createNoteTemplate } from '../../templates';
import history from '../../helpers/history';

interface IState {
  currentNoteId: string;
}

class Note extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      currentNoteId: this.props.urlNoteId,
    };
    const { downloadNNotes, displayNote, urlNoteId, userNotes } = this.props;
    //if userNotes is empty, download 10 most recent notes;
    if (Object.keys(userNotes).length <= 1) {
      downloadNNotes(10);
    }
    displayNote(urlNoteId);
    this.props.history.listen(location => ReactGA.pageview(location.pathname));
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeydownUp);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { redirect, userNotes, history, urlNoteId } = this.props;
    if (Object.keys(userNotes).length !== 1 && redirect) {
      //if redirect is triggered, redirect user to the most recently available note
      //ex. redirects user upon first sign in, or invalid url
      const arrayOfNotes = Object.keys(userNotes).map(noteId => {
        return userNotes[noteId];
      });
      // descending order == newest notes first
      const orderedArrayOfNotes = orderBy(arrayOfNotes, 'updatedAt', 'desc');

      const mostRecentAvailableNoteId = orderedArrayOfNotes[0].noteId;
      history.push(`/notes/${mostRecentAvailableNoteId}`);
    }

    // to redirect to new note
    if (urlNoteId !== this.state.currentNoteId) {
      history.push(`/notes/${this.state.currentNoteId}`);
    }

    //to guard against exit with unsaved changes
    if (this.props.hasChanges) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  public componentWillUnmount() {
    const {
      hasChanges,
      noteExistsinDB,
      saveCurrentNote,
      urlNoteId,
    } = this.props;
    //if note exists in DB, save to Firestore.
    //handles case where user visits bad url link and gets redirected
    //otherwise, would auto-save a non existent, empty note
    if (!noteExistsinDB && hasChanges) {
      saveCurrentNote(urlNoteId);
    }
    document.onkeydown = null;
  }

  public render() {
    const {
      hasChanges,
      isCurrentNoteLoading,
      urlNoteId,
      currentNote,
      restoreNoteFromTrash,
      permDeleteNote,
      createNote,
      email,
    } = this.props;

    const { currentNoteId } = this.state;

    if (!currentNoteId) {
      return <EmptySelectionPage onNewNoteClick={this.handleNewNoteClick} />;
    }

    if (isCurrentNoteLoading || !currentNote) {
      return <NoteLoader />;
    }

    return (
      <NoteView>
        <NoteContentWrapper>
          <Prompt
            when={hasChanges}
            message="You have unsaved changes, are you sure you want to leave?"
          />
          {currentNote.inTrash ? (
            <WarningBar
              noteId={currentNote.noteId as string}
              restoreNoteFromTrash={restoreNoteFromTrash}
              permDeleteNote={permDeleteNote}
            />
          ) : (
            <ToolbarContainer urlNoteId={this.props.urlNoteId} />
          )}

          <NoteHeader
            title={currentNote.title}
            onChange={this.handleTitleChange}
          />
          <AlignedEditor
            editorState={currentNote.editorState}
            readOnly={false}
            onChange={this.handleAlignedEditorChange}
          />
        </NoteContentWrapper>
      </NoteView>
    );
  }

  private handleTitleChange = (event: any) => {
    //updateTitle
    const { urlNoteId, updateTitleInStore } = this.props;
    updateTitleInStore(urlNoteId, event.target.value);
  };

  private handleAlignedEditorChange = (editorState: IEditorState) => {
    const { urlNoteId, updateEditorStateInStore } = this.props;
    updateEditorStateInStore(urlNoteId, editorState);
  };

  private handleKeydownUp = (e: KeyboardEvent) => {
    e = e || event;
    if ((e.ctrlKey || e.metaKey) && e.which == 83) {
      e.preventDefault();
      if (this.props.hasChanges) {
        this.props.saveCurrentNote(this.props.urlNoteId);
      }
    }
  };

  private handleNewNoteClick = async (e: any) => {
    e.preventDefault();
    const { email, createNote } = this.props;
    // createNoteTemplate(email);
    const newNoteId = await createNote(createNoteTemplate(email));
    this.setState({
      currentNoteId: newNoteId,
    });
  };
}

export { Note };

//styled components and loader

const NoteView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  font-size: 16px !important;
  padding: 0 4% 0 calc(4% + 1.1em);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const NoteContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
`;
