import React from 'react';
import { Layout } from './Layout';
import { styled } from '../common/ThemedStyledComponent';

interface IProps {}

const Error404 = ({  }: IProps) => {
  return (
    <Layout>
      <HeroCenter>
        <h1>Error 404!</h1>
      </HeroCenter>
    </Layout>
  );
};

export { Error404 };

const HeroCenter = styled.div`
  flex: 1 0 50%;
  display: flex;
  justify-content: center;
`;
