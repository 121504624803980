import * as React from 'react';
import { AlignedEditorContext } from './Contexts/AlignedEditorContext';
import Section from './Section';
import { ISection } from '../../data-models';

interface IProps {
  section: ISection;
  sectionsLength: number;
  sectionId: string;
  sectionIndex: number;
}

export default (props: IProps) => (
  <AlignedEditorContext.Consumer>
    {({ collapsed, insertSection, deleteSection, readOnly }) => (
      <Section
        {...props}
        collapsed={collapsed}
        insertSection={insertSection}
        deleteSection={deleteSection}
        readOnly={readOnly}
      />
    )}
  </AlignedEditorContext.Consumer>
);
