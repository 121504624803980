import * as React from 'react';
import { useEffect } from 'react';
import { debounce } from 'lodash';
import { css } from '@emotion/core';
//@ts-ignore
// import Editor from '@brandontle/rich-markdown-editor';
import styled from '@emotion/styled';
// @ts-ignore
import Editor from '@brandontle/react-editor.js';
import { IEditorJsData } from '../..';
import QuillEditor from '../QuillEditor';
import { ISideData } from '../../../../data-models';

interface IProps {
  sectionId: string;
  leftOrRight: 'left' | 'right' | 'both';
  editorData: ISideData;
  readOnly?: boolean;
  updateEditorData: (
    sectionId: string,
    leftOrRight: 'left' | 'right' | 'both',
    updatedData: ISideData
  ) => void;
}

const TextEditor = (props: IProps) => {
  const { leftOrRight, sectionId, readOnly } = props;
  // debounce updateModuleProperties function to .5seconds
  const debouncedupdateEditorData = debounce(props.updateEditorData, 500);

  //flush debouncing on unmount
  useEffect(() => {
    return () => {
      debouncedupdateEditorData.flush();
    };
  }, []);

  useEffect(() => {
    // const editables = document.querySelectorAll('[contenteditable="true"]');
    // console.log('editables', editables);
    // editables.forEach(element => {
    //   element.setAttribute('contenteditable', 'false');
    // });
  }, []);

  //updates component's state on any change (value and selection)
  //and updates redux store IFF content value changes
  const handleChange = (updatedData: string) => {
    // Guard clause bc RichEditor triggers onChange upon first render
    // if (firstRender) {
    //   // this.setState({
    //   //   firstRender: false,
    //   // });
    //   return;

    // }
    // console.log('editorjs handleChange', sectionId, leftOrRight, updatedData);
    debouncedupdateEditorData(sectionId, leftOrRight, { html: updatedData });
  };

  const { editorData } = props;

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <QuillEditor
      html={editorData.html}
      readOnly={readOnly}
      onChange={handleChange}
      leftOrRight={leftOrRight}
    />
  );
};

export default TextEditor;
