import React from 'react';
import { ThemeProvider } from 'styled-components';
import { styled } from '../common/ThemedStyledComponent';

import { NoteContainer } from '../Note/NoteContainer';
import { SidebarContainer } from '../Sidebar/SidebarContainer';
import { theme } from '../common/ThemedStyledComponent';

interface IProps {}

const Main = ({  }: IProps) => {
  return (
    <ThemeProvider theme={theme}>
      <MainView>
        <SidebarContainer />
        <NoteContainer />
      </MainView>
    </ThemeProvider>
  );
};

export { Main };

// use withRouter to provide this.props.history to Sidebar
// const SidebarContainerWithRouter = withRouter(Main as any);

// export { SidebarContainerWithRouter as Main };

//styles
const MainView = styled.div`
  height: 100vh;
  /* makes the body non-scrollable */
  overflow: hidden;
  margin: 0px;
  display: flex;
  box-sizing: border-box;
`;
