import * as React from 'react';

import { ISections, ISection, ISideData } from '../../../data-models';
import { IEditorJsData } from '..';

interface IProps {
  sectionsOrder: string[];
  sections: ISections;
  children?: React.ReactNode;
  insertSection: (sectionIndex: number, sectionToInsert: ISection) => void;
  deleteSection: (sectionId: string) => void;
  updateSectionOrder: (oldIndex: number, newIndex: number) => void;
  updateEditorData: (
    sectionId: string,
    leftOrRight: 'left' | 'right' | 'both',
    updatedData: ISideData,
  ) => void;
  collapsed: boolean;
  readOnly?: boolean;
}

export const AlignedEditorContext = React.createContext({} as IProps);

export const AlignedEditorProvider = (props: IProps) => {
  return (
    <AlignedEditorContext.Provider value={{ ...props }}>
      {props.children}
    </AlignedEditorContext.Provider>
  );
};
