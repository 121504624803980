import * as React from 'react';
import { useState } from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';

interface IProps {
  items: string[];
  defaultValue: string;
  isLoading: boolean;
  onChange: (value: string) => any;
  layout?: 'comfortable' | 'compact' | 'full';
  maxItems?: number;
  orientation?: 'vertical' | 'horizontal';
}

export const SelectorMenu = (props: IProps) => {
  const {
    items,
    defaultValue,
    isLoading,
    onChange,
    layout,
    maxItems,
    orientation,
  } = props;

  const [activeStatus, setActiveStatus] = useState(defaultValue);

  if (isLoading) {
    return null;
  }

  const ButtonList = items.map((value, index) => {
    if (maxItems && index >= maxItems) {
      return;
    }
    return (
      <Button
        key={value}
        onClick={() => {
          setActiveStatus(value);
          onChange(value);
        }}
        style={{
          color: activeStatus === value ? 'black' : 'grey',
          // fontWeight: activeStatus === value ? 600 : 'inherit',
          background: activeStatus === value ? '#EAEAEA' : 'initial',
        }}
        layout={layout}
        orientation={orientation}
      >
        {value}
      </Button>
    );
  });

  return (
    <SelectorContainer layout={layout} orientation={orientation}>
      {ButtonList}
    </SelectorContainer>
  );
};

interface ISelectorSyleProps {
  layout?: 'comfortable' | 'compact' | 'full';
  orientation?: 'vertical' | 'horizontal';
}

const SelectorContainer = styled('div')<ISelectorSyleProps>`
  display: ${props => (props.orientation === 'horizontal' ? 'flex' : 'unset')};
  width: ${props => {
    if (props.layout === 'compact') {
      return '150px';
    } else if (props.layout === 'comfortable') {
      return '175px';
    } else if (props.layout === 'full') {
      return '100%';
    }
  }};
  ul {
    list-style-type: none;
    padding: 0px;
  }
`;

const Button = styled('button')<ISelectorSyleProps>`
  cursor: pointer;
  display: flex;
  padding: ${props => {
    if (props.orientation === 'horizontal') {
      return '5px 10px';
    } else if (props.layout === 'compact') {
      return '5px 5px';
    } else {
      return '8px 5px';
    }
  }};
  font-size: ${props => (props.layout === 'compact' ? '14px' : '16px')};
  border-radius: 4px;
  box-sizing: border-box;
  color: #2d2d2d;
  border: unset;
  background: transparent;
  transition: box-shadow 150ms ease;
  width: fit-content;
  min-width: fit-content;
  height: fit-content;
  flex: 1;
  &:hover {
    background: #eaeaea;
    /* box-shadow: 0 10px 30px -5px rgba(10, 16, 34, 0.2); */
  }
  &:active {
    outline: 0;
  }
  &:focus {
    outline: 0;
  }
`;
