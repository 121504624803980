import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { INote } from '../data-models';
import { heapExampleImg, heapSortExampleImg } from './longData';

const firestore = firebase.firestore;

export const algorithmsExample: INote = {
  noteId: 'algorithmsExample',
  ownerIds: [''],
  title: 'Algorithms Notes',
  inTrash: false,
  default: true,
  createdAt: firestore.Timestamp.now(),
  updatedAt: firestore.Timestamp.now(),
  editorState: {
    sectionsOrder: [
      'section-1',
      'section-2',
      'section-3',
      'section-4',
      'section-5',
    ],
    sections: {
      'section-1': {
        sectionId: 'section-1',
        left: {
          html: `<p>These notes are from the MIT 6.006 Introduction to Algorithms course.</p>`,
        },
        right: {
          html: `<iframe class="ql-video" frameborder="0" allowfullscreen="true" src="https://www.youtube.com/embed/B7hVxCmfPtM?showinfo=0"></iframe>`,
        },
      },
      'section-2': {
        sectionId: 'section-2',
        left: {
          html: `<h2>Priority Queues</h2>
          <p>A data structure with a set S of elements where:</p>
          <ul><li>each element includes a priority value that determines its place in the queue</li><li>the queue is ordered from highest priority to lowest</li><li>each element has a key</li><li>each element can support the following operations:</li></ul>`,
        },
        right: {
          html: `<pre class='ql-syntax' spellcheck='false'>insert(S,x): insert element x into set S\n\nmax(S): return element of S with largest key\n\nextract_max(S): return element of S with largest key and remove it from S\n\nincrease_key(S,x,k): increase the value of element x’s key to new value k (assumed to be as large as current value)\n
          </pre><br/>`,
        },
      },
      'section-3': {
        sectionId: 'section-3',
        left: {
          html: `<h2>Heaps</h2>
          <ul>
          <li>Implementation of a priority queue</li>
          <li>
          An array, visualized as a nearly complete binary tree</li>
          <li>
          Max Heap Property: The key of a node is ≥ than the keys of its children (Min Heap defined analogously)</li>
          </ul>
          `,
        },
        right: {
          html: heapExampleImg,
        },
      },
      'section-4': {
        sectionId: 'section-4',
        left: {
          html: `<h2>Heap-Sort Sorting Strategy:</h2>
          <ol>
          <li>Build Max Heap from unordered array
          <li>
          Find maximum element A[1];
          </li>
          <li>
          Swap elements A[n] and A[1]: now max element is at the end of the array!
          <lli>
          <li>
          Discard node n from heap (by decrementing heap-size variable)
          </li>
          <li>
          New root may violate max heap property, but its children are max heaps. Run max_heapify to fix this.
          </li>
          <li>
          Go to Step 2 unless heap is empty.</li>
          </ol>`,
        },
        right: {
          html: heapSortExampleImg,
        },
      },
      'section-5': {
        sectionId: 'section-5',
        left: {
          html: `<h2>Run time</h2>
          <ul>
          <li>
          after n iterations the Heap is empty
          </li>
          <li>
          every iteration involves a swap and a max_heapify operation
          </li>
          <li>
          therefore, takes O(log n) time</li><ul>`,
        },
        right: {
          html: ``,
        },
      },
    },
  },
};

// export const algorithmsExample:INote = {
//   noteId: 'algorithmsExample',
//   ownerIds: [''],
//   title: 'Algorithms Notes Example',
//   inTrash: false,
//   default: true,
//   createdAt: firestore.Timestamp.now(),
//   updatedAt: firestore.Timestamp.now(),
//   sectionsOrder: [
//     'section-1',
//     'section-2',
//     'section-3',
//     'section-4',
//     'section-5',
//   ],
//   sections: {
//     'section-1': {
//       id: 'section-1',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `These notes are from the MIT 6.006 Introduction to Algorithms course.`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'link',
//           payloadObj: {
//             link: `https://www.youtube.com/watch?v=B7hVxCmfPtM`,
//             linkType: `Video`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-2': {
//       id: 'section-1',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Priority Queues
//             \nA data structure implementing a set S of elements, each associated with a key, supporting the following operations:`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'code',
//           payloadObj: {
//             code: `insert(S,x): insert element x into set S\n\nmax(S): return element of S with largest key\n\nextract_max(S): return element of S with largest key and remove it from S\n\nincrease_key(S,x,k): increase the value of element x’s key to new value k (assumed to be as large as current value)\n`,
//             settings: {
//               languageLabel: 'Markdown',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-3': {
//       id: 'section-3',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Heap
//             \n* Implementation of a priority queue
//             \n* An array, visualized as a nearly complete binary tree
//             \n* Max Heap Property: The key of a node is ≥ than the keys of its children (Min Heap defined analogously)`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'link',
//           payloadObj: {
//             link: `https://i.ytimg.com/vi/W81Qzuz4qH0/maxresdefault.jpg`,
//             linkType: `Image`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-4': {
//       id: 'section-4',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1', 'module-2', 'module-3', 'module-4'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Heap-Sort Sorting Strategy:
//             \n1. Build Max Heap from unordered array
//             \n2. Find maximum element A[1];
//             \n3. Swap elements A[n] and A[1]: now max element is at the end of the array!
//             \n4. Discard node n from heap (by decrementing heap-size variable)
//             \n5. New root may violate max heap property, but its children are max heaps. Run max_heapify to fix this.
//             \n6. Go to Step 2 unless heap is empty.`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'link',
//           payloadObj: {
//             link: `https://i.imgur.com/IoUZ4SH.png`,
//             linkType: `Image`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//         'module-2': {
//           id: 'module-2',
//           type: 'link',
//           payloadObj: {
//             link: `https://i.imgur.com/WmT0TcO.png`,
//             linkType: `Image`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//         'module-3': {
//           id: 'module-3',
//           type: 'link',
//           payloadObj: {
//             link: `https://i.imgur.com/QNCaxb8.png`,
//             linkType: `Image`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//         'module-4': {
//           id: 'module-4',
//           type: 'link',
//           payloadObj: {
//             link: `https://i.imgur.com/jvsYEt1.png`,
//             linkType: `Image`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-5': {
//       id: 'section-5',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: [],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Run time
//             \n* after n iterations the Heap is empty
//             \n* every iteration involves a swap and a max_heapify operation
//             \n* therefore, takes **O(log n) time**`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {},
//     },
//   },
// };
