import React, { Component } from 'react';
import { styled } from '../../common/ThemedStyledComponent';
import { IToolbarProps as IProps } from './ToolbarContainer';
import * as Icon from 'react-feather';
import Dropdown from 'react-bootstrap/Dropdown';

interface IState {}

class Toolbar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render() {
    const { hasChanges, moveNoteToTrash, urlNoteId } = this.props;
    return (
      <ToolbarStyled>
        <Max1200pxContainer>
          <Left hasChanges={hasChanges}>
            {hasChanges ? (
              <ClickToSaveButton
                onClick={e => {
                  this.handleSaveNoteClick(e);
                }}
              >
                <Icon.Save size={15} style={{ paddingRight: '2px' }} /> Save
                {this.getUserSaveText()}
              </ClickToSaveButton>
            ) : (
              <SavedButton className="disabled">
                <Icon.Check size={15} style={{ paddingRight: '2px' }} /> Saved
              </SavedButton>
            )}
          </Left>

          <Right>
            <Dropdown id="dropdown-menu-align-right">
              <Dropdown.Toggle
                //@ts-ignore
                variant="transparent"
                style={{ backgroundColor: 'white' }}
              >
                <Icon.MoreHorizontal size={20} color="#848484" />
              </Dropdown.Toggle>
              {/*
            //@ts-ignore: Dropdown.Menu typings does nto include alignRight */}
              <Dropdown.Menu alignRight style={{ maxWidth: '50px' }}>
                <Dropdown.Item
                  onClick={() => {
                    moveNoteToTrash(urlNoteId);
                  }}
                  style={{ maxWidth: 'fit-content' }}
                >
                  Delete Note
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Right>
        </Max1200pxContainer>
      </ToolbarStyled>
    );
  }

  private handleSaveNoteClick = (e: any) => {
    this.props.saveCurrentNote(this.props.urlNoteId);
  };

  private getUserSaveText = () => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const isMobile = /Mobi/.test(navigator.userAgent);
    if (isMac) {
      return ' (Cmd + S)';
    } else if (isMobile) {
      return '';
    } else {
      return ' Ctl + S';
    }
  };
}

export { Toolbar };

const ToolbarStyled = styled.div`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-height: fit-content;
  transition: all 0.4s;
  padding: 10px 0px 0px 8px;
  /* padding: 11px 4% 11px calc(4% + 1.4em); */
  /* for when using padding with width */
  box-sizing: border-box;
  justify-content: center;
  /* sticky toolbar */
  position: sticky;
  top: 0;
  z-index: 100;
  height: fit-content;
`;

const Max1200pxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* max-width: 1200px; */
  max-height: fit-content;
`;

interface IToolbarStyledProps {
  hasChanges: boolean;
}

//styled components typescript props
const Left = styled.div<IToolbarStyledProps>`
  flex-grow: 1;
  opacity: ${props => (props.hasChanges ? 1 : 0)};
  transition: ${props => (props.hasChanges ? 'none' : 'all .4s 2.1s')};
  &:hover {
    opacity: 1;
    transition: all 0.4s;
  }
  @media (max-width: 769px) {
    margin-left: 1.5%;
  }
  @media (max-width: 670px) {
    margin-left: 2%;
  }
  @media (max-width: 550px) {
    margin-left: 1.1em;
  }
  @media (max-width: 300px) {
    margin-left: 1.5em;
  }
`;

const Right = styled.div`
  display: flex;
  align-items: center;
`;

const ClickToSaveButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 12px;
  border: none;
  background-color: rgba(255, 107, 107, 1);
  color: #fff;
  padding: 4px 6px;
  height: 30px;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 4px;
  &:active {
    background-color: rgba(255, 107, 107, 0.95);
  }
  &:hover {
    background-color: rgba(255, 107, 107, 0.95);
  }
  &:focus {
    outline: 4px auto #e73;
  }
`;

const SavedButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 12px;
  border: none;
  box-sizing: border-box;
  background-color: white;
  color: #4fb15f;
  outline: none;
  padding: 4px 6px;
  height: 30px;
  border-radius: 4px;
  &:hover {
    background-color: rgba(233, 230, 237, 1);
  }
  &:active {
    background-color: rgba(233, 230, 237, 0.8);
  }
`;
