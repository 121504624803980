import {
  INotesObj,
  INotesLocalStore,
  ICurrentNoteStatus,
} from '../store/reducers';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
// import { welcomeNote } from './welcome';
// import { exampleNote1 } from './exampleNote1';
import { algorithmsExample } from './algorithmsExample';
import { firestoreExample } from './firestoreExample';
import { homeNote } from './homeNote';
import { defaultNewNote } from './defaultNewNote';
// import { meetingExample } from './meetingExample';
import { INote, IUtility } from '../data-models';
import { globalOptimizationExample } from './natureAlgoExample';

const firestore = firebase.firestore;

export const createNoteTemplate = (ownerId: string, type?: string): INote => {
  let newNote = Object.assign({}, defaultNewNote);
  switch (type) {
    case 'welcome':
      // newNote = Object.assign({}, welcomeNote);
      break;
    // case 'example1':
    //   newNote = Object.assign({}, exampleNote1);
    //   break;
    case 'globalOptimizationExample':
      newNote = Object.assign({}, globalOptimizationExample);
      break;
    case 'firestoreExample':
      newNote = Object.assign({}, firestoreExample);
      break;
    case 'algorithmsExample':
      newNote = Object.assign({}, algorithmsExample);
      break;
    case 'meetingExample':
      // newNote = Object.assign({}, meetingExample);
      break;
    case 'home':
      newNote = Object.assign({}, homeNote);
      break;
    default:
      newNote = Object.assign({}, defaultNewNote);
      newNote.default = false;
  }
  if (
    type !== 'home' &&
    type !== 'algorithmsExample' &&
    type !== 'firestoreExample'
  ) {
    newNote.createdAt = firestore.Timestamp.now();
    newNote.updatedAt = firestore.Timestamp.now();
    newNote.default = false;
  }
  newNote.ownerIds[0] = ownerId;
  return Object.assign({}, newNote);
};

// export const createBlankCodeEditor = () => {
//   let newCodeEditor = Object.assign({}, blankCodeEditor);
//   newCodeEditor.id = Math.random()
//     .toString(36)
//     .substr(2, 9);
//   return newCodeEditor;
// };

const currentNoteStatus: ICurrentNoteStatus = {
  hasChanges: false,
  isLoading: true,
  noteId: '',
  noteExistsInDB: false,
};

const homeDemo = createNoteTemplate('id', 'home');
const algorithmsDemo = createNoteTemplate('id', 'algorithmsExample');
const firestoreDemo = createNoteTemplate('id', 'firestoreExample');
const globalOptimizationDemo = createNoteTemplate('id', 'globalOptimizationExample');

export const defaultNotesReducer: INotesLocalStore = {
  redirect: false,
  userNotes: {
    notes: {},
    isLoading: true,
  },
  defaultNotes: {
    homeDemo,
    algorithms: algorithmsDemo,
    firestore: firestoreDemo,
    'global optimization': globalOptimizationDemo
  },
  currentNoteStatus,
};

export const defaultUtilityReducer: IUtility = {
  sidebarCategory: 'All',
  sidebarOrderLabel: 'updatedAt',
  sidebarAscOrDesc: 'desc',
};
