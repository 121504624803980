import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { exportNotes } from '../../helpers/exporter';
import { INotesObj } from '../../store/reducers';

interface IState {}
interface IProps {
  show: boolean;
  onHide(): void;
  userEmail: string;
  notes: INotesObj;
}

class CenteredModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form>
              <Form.Group controlId="forBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  disabled
                  type="email"
                  size="sm"
                  placeholder={this.props.userEmail}
                />
                <Form.Text className="text-muted">
                  Please message support to change your email.
                </Form.Text>
              </Form.Group>
              <p/>
              <Form.Group controlId="forExport">
                <Form.Label>Export Notes (beta)</Form.Label>
                <Form.Text className="text-muted">
                  Converts all notes to single page markdown files for download.
                  Note that markdown does not support WingNotes' two-column
                  structure, therefore the right column will be
                  collapsed underneath each corresponding section for each note.
                </Form.Text>
                <Button onClick={() => exportNotes(this.props.notes)} size="sm" style={{marginTop: '6px'}}>
                  Export
                </Button>
              </Form.Group>
            </Form>
            {/* <Button disabled variant="primary" size="sm" type="submit">
            Save
          </Button> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export { CenteredModal };
