import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
// @ts-ignore
import TextLoop from 'react-text-loop';
// @ts-ignore
import Parallax from 'react-rellax';
// @ts-ignore
import TextareaAutosize from 'react-textarea-autosize';

import { styled } from '../common/ThemedStyledComponent';
import { Layout } from './Layout';

import { ISections, INote } from '../../data-models';
import {
  // updateSectionOrder,
  setUpNewUser,
  createNote,
} from '../../store/actions';
import { IAppState, INotesObj } from '../../store/reducers';
import { Link } from 'react-router-dom';
import LoginForm from '../auth/LoginForm';
import { AlignedEditor, IEditorState } from '../AlignedEditor';
import { SelectorMenu } from '../common/SelectorMenu';

interface IState {
  editorState: IEditorState;
}

interface IProps {
  editorState: IEditorState;
  isLoading: boolean;
}

class DemoEditor extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editorState: this.props.editorState
    };
  }
  componentDidUpdate(prevProps: IProps) {
    if(prevProps.editorState !== this.props.editorState)
    this.setState({
      editorState: this.props.editorState
    })
  }
  render() {
    // const { notes } = this.props;
    const { isLoading } = this.props;
    const { editorState } = this.state;

    if(isLoading) {
      return null;
    }
    return (
        <NoteDemoEditor>
          <AlignedEditor
            editorState={this.props.editorState}
            readOnly={false}
            onChange={() => {}}
            demo={true}
          />
        </NoteDemoEditor>
    );
  }
}

export { DemoEditor };

const HeroContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 40px auto 30px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 15vh;
  justify-content: center;
  /* background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url('../hero_banner.svg'); */
  /* background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center; */
`;
const HeroCenter = styled.div`
  margin: 150px 0px 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  img {
    max-width: 100%;
    opacity: 0.6;
    margin-top: -250px;
    z-index: -2;
  }
  h1 {
    font-size: 2.5rem;
  }
  @media (max-width: 550px) {
    margin: 150px 0px 100px;
    h1 {
      font-size: 2rem;
    }
  }
`;

const HeroCTA = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    margin-top: 5px;
  }
`;

const DemoEditorelectorStyles = css`
  display: flex;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: -380px;
`;

const NoteDemoHeader = styled.div`
  padding: 0px 4% 0px 5.5%;
  width: 100%;
  box-sizing: border-box;
`;

const NoteDemoEditor = styled.div`
  padding: 0px 4% 0px 4.5%;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 720px) {
    padding: 0px 4% 0px 5.5%;
  }
  @media (max-width: 600px) {
    padding: 0px 4% 0px 6.5%;
  }
  @media (max-width: 500px) {
    padding: 0px 4% 0px 7.5%;
  }
  @media (max-width: 400px) {
    padding: 0px 4% 0px 8.5%;
  }
  @media (max-width: 350px) {
    padding: 0px 4% 0px 9.5%;
  }
`;

const StyledTextAreaAutosize = styled(TextareaAutosize)`
  border: 0px;
  background-color: transparent;
  padding: 4px 0px 4px 4px;
  font-family: 'Inter UI', Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 600;
  resize: none;
  overflow-wrap: break-word;
  width: 100%;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;

const NoteDemo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 7px;
  z-index: 10;
  background: white;
  box-shadow: 0px 5px 50px -5px #e4e4e4;
  padding: 70px 0px 30px;
  margin: 0px auto 150px;
`;

const StyledTextLoop = styled(TextLoop)`
  display: flex;
  align-items: center;
  width: 275px;
  padding-bottom: 15px;
  border-bottom: 4px solid black;
  @media (max-width: 550px) {
    padding: 3px;
    border-bottom: 3px solid black;
  }
`;

const CenteredTextLoopText = styled.div`
  display: flex;
  width: 275px;
  justify-content: center;
`;

const Input = styled.input`
  display: flex;
  border: 0px;
  flex: 1.5 1;
  box-shadow: none;
  background-color: transparent;
  font-size: 2em;
  font-weight: 600;
  max-height: fit-content;
  /* to compensate for note's left-side buttons */
  padding: 10px 0px 10px 2px;
  flex: 1.5 1;
  width: 100%;
  /* to establish border width so that hover does not move Input */
  border: 1px solid transparent;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  /* border: 1px solid #adadad; */
  background-color: #4e42ff;
  box-shadow: 0 1px 1px 0 rgba(10, 16, 34, 0.2);
  transition: all 0.1s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const FooterCTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto 40px;
`;

const smallScreenWarningStyles = css`
  display: none;
  margin: 0px 4% 10px 5.5%;
  padding: 0px 6px;
  width: auto;
  box-sizing: border-box;
  background: #ffb6b6;
  border-radius: 4px;
  @media (max-width: 768px) {
    display: unset;
  }
`;
