import { ISection, ISideData } from '../../../data-models';

export const blankSection = () => {
  const newBlankSection = {
    sectionId: '',
    left: Object.assign({}, { html: '' }) as ISideData,
    right: Object.assign({}, { html: '' }) as ISideData,
  } as ISection;
  return Object.assign({}, newBlankSection);
};

export const createBlankSection = () => {
  const newBlankSection = blankSection();
  newBlankSection.sectionId = Math.random()
    .toString(36)
    .substr(2, 9);

  return Object.assign({}, newBlankSection);
};
