import React from 'react';
import { styled } from '../../common/ThemedStyledComponent';
import Button from 'react-bootstrap/Button';

interface IProps {
  restoreNoteFromTrash(noteId: string): void;
  permDeleteNote(noteId: string): void;
  noteId: string;
}

const WarningBar = ({
  restoreNoteFromTrash,
  permDeleteNote,
  noteId,
}: IProps) => {
  return (
    <StyledWrapper>
      <span>This note is currently in the trash. </span>
      <Button size="sm" variant='outline-primary' onClick={() => restoreNoteFromTrash(noteId)} style={{marginLeft: '4px'}}>
        Restore
      </Button>
      <Button size="sm" variant='outline-primary' onClick={() => permDeleteNote(noteId)} style={{marginLeft: '4px'}}>
        Permenently Delete
      </Button>
    </StyledWrapper>
  );
};

export { WarningBar };

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  padding: 4px;
  background-color: #fbcdcd;
  border-radius: 4px;
  align-items: center;
  transition: all 0.4s;
`;
