import * as React from 'react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button } from 'semantic-ui-react';
//@ts-ignore
import styled from '@emotion/styled/macro'
import * as Icon from 'react-feather';

interface IProps {
  onClick(e: any, first?: boolean): void;
  first?: boolean;
}

const InsertSectionButton = (props: IProps) => {
  return (
    <div css={fullWidthContainerStyles}>
      <div css={insertButtonWrapperStyles}>
        <button css={buttonStyles} onClick={e => props.onClick(e, props.first)}>
          <Icon.PlusCircle size={20} color="#848484" />
        </button>
      </div>
      <div css={max1200pxContainerStyles} />
    </div>
  );
};

export default InsertSectionButton;

const insertButtonWrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 0px;
  transition: all 0.4s;
  position: relative;
  top: 7px;
  right: -1.1em;
  /* margin-left: -30px;
  padding-right: 15px; */
`;

const fullWidthContainerStyles = css`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-height: fit-content;
  box-sizing: border-box;
  justify-content: center;
  background: #f9f9fb;
  box-shadow: none;
  border-radius: 4px;
  opacity: 0;
  /* //FUTURE: fix so that hovering over FullWidthContainer of insertSectionButton reveals button */
  &:hover {
    opacity: 1;
    transition: all 0.4s;
    background: #f3f3f3;
  }
`;

const max1200pxContainerStyles = css`
  width: 100%;
  max-width: 1200px;
  height: 40px;
`;

const StyledSideButtons = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 0px;
  opacity: 0;
  transition: all 0.4s;
  position: relative;
  top: 7px;
  right: 1.1em;
  /* margin-left: -30px;
  padding-right: 15px; */
`;

const buttonStyles = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: none;
  background: transparent;
  padding: 2.5px;
  &:hover {
    background: #e0d9d9;
  }
`;
