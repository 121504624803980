import producer from 'immer';
import { UtilityAction } from '../constants';
import { defaultUtilityReducer } from '../../templates';

export const utility = (state = defaultUtilityReducer, action: any) => {
  return producer(state, draft => {
    switch (action.type) {
      case UtilityAction.showUtlityModal:
        return;
      case UtilityAction.toggleSidebarOrderLabel:
        draft.sidebarOrderLabel =
          draft.sidebarOrderLabel === 'updatedAt' ? 'createdAt' : 'updatedAt';
        return;
      case UtilityAction.toggleSidebarAscOrDesc:
        draft.sidebarAscOrDesc =
          draft.sidebarAscOrDesc === 'asc' ? 'desc' : 'asc';
        return;
      case UtilityAction.updateSidebarCategory:
        draft.sidebarCategory = action.category;
        return;
    }
  });
};
