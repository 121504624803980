import * as React from 'react';
// import * as Icon from 'react-feather';
//@ts-ignore
import styled from '@emotion/styled/macro'
import {
  Dropdown,
  DropdownProps,
  LabelProps,
  DropdownItemProps,
} from 'semantic-ui-react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as Icon from 'react-feather';

interface IProps {
  onSelect(eventKey: string, data?: any): void;
  sectionsLength: number;
  collapsed: boolean;

}

const SectionSettingsButton = (props: IProps) => {
  const sectionSettingTrigger = (
    <span>
      <Icon.Settings size={18} color="#848484" />
    </span>
  );

  const sectionSettingOptions = [
    {
      key: 'delete',
      text: 'Delete Section',
      value: 'delete',
      disabled: props.sectionsLength > 1 ? false : true,
    },
    // {
    //   key: 'collapseExpand',
    //   text: props.collapsed ? 'Expand' : 'Collapse',
    //   value: 'collapse',
    // },
  ];

  const handleSectionSettingChange = (
    event: React.SyntheticEvent,
    data: DropdownProps
  ) => {
    const { value } = data;
    if (value === 'delete') {
      props.onSelect(data.value as string);
    } else if (value === 'collapse') {
      props.onSelect(data.value as string);
    }
  };

  return (
    <Dropdown
      trigger={sectionSettingTrigger}
      onChange={handleSectionSettingChange}
      options={sectionSettingOptions}
      selectOnBlur={false}
      pointing="top left"
      icon={null}
      // empty value nedded to be able to select options multiple times
      value=''
      onMouseDown={(e: any) => e.preventDefault()}
      css={dropdownStyles}
    />
  );
};

export default SectionSettingsButton;

const StyledDropdown = styled(Dropdown)`
  /* margin-left: -28px; */
  display: flex;
  transition: all 0.4s;
`;

const dropdownStyles = css`
  margin-bottom: 4px;
  .icon {
    margin: 0;
  }
`;
