import React, { Component, Fragment } from 'react';
import { styled } from '../common/ThemedStyledComponent';
import { NavLink } from 'react-router-dom';

import * as Icon from 'react-feather';
import newNoteIcon from './newNoteIcon.svg';
import Dropdown from 'react-bootstrap/Dropdown';

import { CenteredModal } from '../common/CenteredModal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ContentLoader from 'react-content-loader';
import { FeedbackModal } from '../common/FeedbackModal';
import { INotesObj } from '../../store/reducers';

interface IProps {
  email: string;
  settingsModalState: boolean;
  feedbackModalState: boolean;
  onShowModalClick: any;
  onHideModalClick: any;
  onSignOutClick: any;
  notes: INotesObj;
}

export const SidebarFooter = (props: IProps) => {
  const {
    email,
    onShowModalClick,
    onHideModalClick,
    onSignOutClick,
    settingsModalState,
    feedbackModalState,
    notes
  } = props;

  return (
    <StyledSidebarFooter>
      {/*
        //@ts-ignore */}
      <Dropdown
        className="dropdown-button"
        style={{ width: '100%', display: 'flex' }}
      >
        <Dropdown.Toggle
          //@ts-ignore
          variant="transparent"
          style={{}}
        >
          <span
            style={{
              textAlign: 'left',
              width: '178px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {email}
          </span>
          <Icon.Settings size={20} color="#848484" />
        </Dropdown.Toggle>
        {/*
          //@ts-ignore */}
        <Dropdown.Menu alignRight>
          <Dropdown.Item onClick={() => onShowModalClick('settings')}>
            Settings
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onShowModalClick('feedback')}>
            Help & Feedback
          </Dropdown.Item>
          <Dropdown.Item onClick={onSignOutClick}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <CenteredModal
        show={settingsModalState}
        onHide={onHideModalClick}
        userEmail={email}
        notes={notes}
      />
      <FeedbackModal show={feedbackModalState} onHide={onHideModalClick} />
    </StyledSidebarFooter>
  );
};

const StyledSidebarFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px 2px 0px;
  font-size: 1.5em;
`;
