import * as React from 'react';
import { Fragment, useEffect } from 'react';
// import { IModules, IModule } from '../../data-models';

//@ts-ignore
import styled from '@emotion/styled/macro';

// import EditorJs from './Modules/EditorJs';
import { IEditorJsData } from '.';
import TextEditor from './Modules/TextEditor';
import { ISideData } from '../../data-models';


interface IProps {
  sectionId: string;
  right: ISideData;
  collapsed: boolean;
}

const Right = (props: IProps) => {
  const { sectionId, right, collapsed } = props;

  return (
    <StyledRightView collapsed={collapsed}>
      <TextEditor
        sectionId={sectionId}
        leftOrRight="right"
        editorData={right}
      />
    </StyledRightView>
  );
};

export { Right };

//styles

interface IStyledRightView {
  collapsed: boolean;
}

const StyledRightView = styled.div<IStyledRightView>`
  /* for sizing */
  flex: 0 0 40%;
  box-sizing: border-box;

  /* for collapsible */
  position: ${(props:IStyledRightView) => (props.collapsed ? 'absolute' : 'unset')};
  left: 60%;
  top: 0;
  bottom: 0;
  right: 0;
  /* overflow-y: ${(props:IStyledRightView) => (props.collapsed ? 'auto' : 'unset')}; */
  /* overflow-x: hidden; */

  /* for aesthetics */
  padding-left: 8px;
  border-left: 2px solid rgba(0, 0, 0, 0.08);

  /* media queries */
  @media (max-width: 769px) {
    padding-left: unset;
    max-width: unset;
    width: 100%;
    flex: 1 0.85 auto;
    border-left: none;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  .codex-editor__redactor {
    padding-bottom: unset;
  }
`;
