import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
//@ts-ignore
import { ReactTypeformEmbed } from 'react-typeform-embed';

interface IState {}
interface IProps {
  show: boolean;
  onHide(): void;
}

class FeedbackModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Help & Feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '400px' }}>
          <ReactTypeformEmbed url="https://brandonle.typeform.com/to/lkUenq" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={this.props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export { FeedbackModal };
