import React, { Component, Fragment } from 'react';
import { styled } from '../common/ThemedStyledComponent';
import { NavLink } from 'react-router-dom';

import * as Icon from 'react-feather';
import newNoteIcon from './newNoteIcon.svg';
import Dropdown from 'react-bootstrap/Dropdown';

import { CenteredModal } from '../common/CenteredModal';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ContentLoader from 'react-content-loader';
import { FeedbackModal } from '../common/FeedbackModal';

interface IProps {
  category: string;
  onNewNoteClick: any;
  updateSidebarCategory: any;
}

export const SidebarHeader = (props: IProps) => {
  const { onNewNoteClick, category, updateSidebarCategory } = props;
  return (
    <StyledSidebarHeader>
      <Dropdown>
        <Dropdown.Toggle
          // @ts-ignore
          variant="transparent"
        >
          <span style={{ fontSize: '15px' }}>{category}</span>
          <Icon.ChevronDown size={20} style={{ marginTop: '.2em' }} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              updateSidebarCategory('All');
            }}
            onMouseDown={(e: any) => e.preventDefault()}
          >
            All
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              updateSidebarCategory('Trash');
            }}
            onMouseDown={(e: any) => e.preventDefault()}
          >
            Trash
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Button
        //@ts-ignore: custom bootstrap class
        variant="transparent"
        onClick={onNewNoteClick}
        style={{ padding: '4px 4px 0px', width: 'fit-content' }}
      >
        <img src={newNoteIcon} alt="newNoteIcon" width="26" height="26" />
      </Button>
    </StyledSidebarHeader>
  );
};

const StyledSidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 6px 15px 8px;
  font-size: 1.5em;
`;
