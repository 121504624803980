import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { IAppState, INotesObj } from '../../store/reducers/index';
import { Sidebar } from './Sidebar';
import {
  createNote,
  signOut,
  updateSidebarCategory,
  toggleSidebarOrderLabel,
  toggleSidebarAscOrDesc,
  downloadNNotes,
  saveCurrentNote,
} from '../../store/actions';
import { INote } from '../../data-models';
import { History } from 'history';

interface IStateToProps {
  email: string;
  notes: INote[];
  notesObj: INotesObj;
  category: string;
  sidebarOrderLabel: string;
  sidebarAscOrDesc: string;
  isLoading: boolean;
  urlNoteId: string;
  history: History;
}
interface IDispatchToProps {
  createNote(newNote: INote): string;
  signOut(): void;
  updateSidebarCategory(category: string): void;
  toggleSidebarOrderLabel(): void;
  toggleSidebarAscOrDesc(): void;
  downloadNNotes(): void;
  saveCurrentNote(noteId: string): void;
}

interface IOwnProps extends RouteComponentProps<MatchParams> {
  // history: History
}

interface MatchParams {
  id: string;
}

export interface ISidebarProps
  extends IStateToProps,
    IDispatchToProps,
    IOwnProps {}

function mapStateToProps(
  state: IAppState,
  ownProps: IOwnProps
): IStateToProps {
  return {
    urlNoteId: ownProps.match.params.id,
    history: ownProps.history,
    notes: Object.keys(state.notesStore.userNotes.notes).map(noteId => {
      return state.notesStore.userNotes.notes[noteId];
    }),
    notesObj: state.notesStore.userNotes.notes,
    email: state.firebase.auth.email,
    category: state.utility.sidebarCategory,
    sidebarOrderLabel: state.utility.sidebarOrderLabel as string,
    sidebarAscOrDesc: state.utility.sidebarAscOrDesc as string,
    isLoading: state.notesStore.userNotes.isLoading,
  };
}

//FUTURE: fix 'any' typing
function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    createNote: bindActionCreators(createNote, dispatch),
    signOut: bindActionCreators(signOut, dispatch),
    updateSidebarCategory: bindActionCreators(updateSidebarCategory, dispatch),
    toggleSidebarOrderLabel: bindActionCreators(
      toggleSidebarOrderLabel,
      dispatch
    ),
    toggleSidebarAscOrDesc: bindActionCreators(
      toggleSidebarAscOrDesc,
      dispatch
    ),
    downloadNNotes: bindActionCreators(downloadNNotes, dispatch),
    saveCurrentNote: bindActionCreators(saveCurrentNote, dispatch)
  };
}

export const SidebarContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Sidebar) as any;
