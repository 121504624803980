import { NoteAction } from '../constants';
import { defaultNotesReducer } from '../../templates';
import producer from 'immer';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { arrayMove } from 'react-sortable-hoc';
import { INote } from '../../data-models';

const firestore = firebase.firestore;

//of type INotesLocalStore
export const notesReducer = (state = defaultNotesReducer, action: any) => {
  // producer creates a copy of the state (draft)
  // draft is able to be mutated
  // and immutable object based on draft is then returned

  return producer(state, draft => {
    switch (action.type) {
      case NoteAction.downloadNoteLoading:
        draft.currentNoteStatus.isLoading = true;
        return;
      case NoteAction.downloadNoteSuccess:
        const urlParamsId = action.urlParamsId;
        draft.userNotes.notes[urlParamsId] = action.downloadedNote;
        draft.userNotes.isLoading=false;
        draft.currentNoteStatus.isLoading = false;
        return;
      case NoteAction.downloadNoteError:
        draft.currentNoteStatus.isLoading = false;
        draft.userNotes.isLoading=false;
        return;

      case NoteAction.downloadNNotesLoading:
        draft.userNotes.isLoading = true;
        return;
      case NoteAction.downloadNNotesSuccess:
        // merge new notes with already-downloaded notes
        draft.userNotes.notes = Object.assign(
          draft.userNotes.notes,
          action.nNotes
        );
        draft.userNotes.isLoading = false;
        return;
      case NoteAction.downloadNNotesError:
        draft.userNotes.isLoading = false;
        return;

      case NoteAction.createNote:
        //add newNote to downloadedUserNotes object (in local storage)
        const newNote: INote = Object.assign({}, action.newNote);
        newNote.noteId = action.newNoteId;
        newNote['default'] = false;
        draft.userNotes.notes[action.newNoteId] = newNote;
        draft.userNotes.notes[action.newNoteId].noteId = action.newNoteId;
        draft.userNotes.notes[action.newNoteId].default = false;
        return;

      case NoteAction.createNoteError:
        return;

      case NoteAction.redirect:
        draft.redirect = action.redirect;
        return;

      case NoteAction.getNoteError:
        //FUTURE: add array of error messages
        return;

      // case NoteAction.isCurrentNoteDownloaded:
      //   draft.currentNoteStatus.isDownloaded = action.isCurrentNoteDownloaded;
      //   return;

      case NoteAction.noteExistsInDB:
        draft.currentNoteStatus.noteExistsInDB = action.noteExistsInDB;
        return;

      case NoteAction.setNoteUpdated:
        draft.userNotes.notes[
          action.noteId
        ].updatedAt = firebase.firestore.Timestamp.now();
        return;

      case NoteAction.updateEditorStateInStore:
        draft.userNotes.notes[action.noteId].editorState = action.editorState;
        return;

      case NoteAction.setNoteIdOnDisplay:
        draft.currentNoteStatus.noteId = action.urlParamsId;
        return;

      case NoteAction.updateTitleInStore:
        var noteId = action.noteId;
        draft.userNotes.notes[noteId].title = action.updatedTitle;
        return;
      case NoteAction.moveNoteToTrash:
        var noteId = action.noteId;
        draft.userNotes.notes[noteId].inTrash = true;
        return;
      case NoteAction.restoreNoteFromTrash:
        var noteId = action.noteId;
        draft.userNotes.notes[noteId].inTrash = false;
        return;
      case NoteAction.hasChanges:
        draft.currentNoteStatus.hasChanges = action.hasChanges;
        return;
      //default case not needed bc immer returns original state if draft is not edited
    }
  });
};
