import firebase from 'firebase/app';
import 'firebase/firestore';
import { INote } from '../data-models';

const firestore = firebase.firestore;

export const firestoreExample: INote = {
  noteId: 'firestoreExample',
  ownerIds: [''],
  title: 'Firestore API Notes',
  inTrash: false,
  default: true,
  createdAt: {} as firebase.firestore.Timestamp,
  updatedAt: {} as firebase.firestore.Timestamp,
  editorState: {
    sectionsOrder: [
      'section-1',
      'section-2',
      'section-3',
      'section-4',
      'section-5',
    ],
    sections: {
      'section-1': {
        sectionId: 'section-1',
        left: {
          html: `<h2>Setup Development Environment</h2>
          <p>
          After setting up the app's Firebase project on the Firebase website, add the Cloud Firestore library to the app. Install the firebase package using npm and import the module into your main app file.</p>`,
        },
        right: {
          html: `<div class="ql-editor" data-gramm="false" contenteditable="true" data-placeholder="Place your references here..."><pre class="ql-syntax" spellcheck="false">//in root directory\nnpm <span class="hljs-keyword">install</span> firebase@<span class="hljs-number">5.8.0</span> <span class="hljs-comment">--save  </span>\n\n//<span class="hljs-keyword">in</span> your app <span class="hljs-keyword">or</span> config file\nconst firebase = require(<span class="hljs-string">"firebase"</span>);\n// Required for side-effects\nrequire("firebase/firestore");
          </pre></div>`,
        },
      },
      'section-2': {
        sectionId: 'section-2',
        left: {
          html: `<h2>Initialize Cloud Firestore</h3>
          <p>Next, initialize an instance of Cloud Firestore.
          </p>
          <br/>
          <p>
          You'll need to insert the apiKey, authDomain, and projectId found on the app's Firebase project website.
          </p>
          `,
        },
        right: {
          html: `<pre class='ql-syntax' spellcheck='false'>// Initialize Cloud Firestore through Firebase\nfirebase.initializeApp({\n  apiKey: '### FIREBASE API KEY ###',\n  authDomain: '### FIREBASE AUTH DOMAIN ###',\n  projectId: '### CLOUD FIRESTORE PROJECT ID ###'\n});\n\nvar db = firebase.firestore();
          </pre><br/>`,
        },
      },
      'section-3': {
        sectionId: 'section-3',
        left: {
          html: `<h2>Add data</h2>
          <p>
          Cloud Firestore stores data in Documents, which are stored in Collections. Cloud Firestore creates collections and documents implicitly the first time you add data to the document. You do not need to explicitly create collections or documents.
          </p>
          </br>
          <p>
          Create a new collection and a document using the following example code.
          </p>
          </br>
          <p>
          Now add another document to the users collection. Notice that this document includes a key-value pair (middle name) that does not appear in the first document. Documents in a collection can contain different sets of information.</p>
          `,
        },
        right: {
          html: `<pre class='ql-syntax' spellcheck='false'>db.collection("users").add({\n  first: "Ada",\n  last: "Lovelace",\n  born: 1815\n})\n.then(function(docRef) {\n  console.log("Document written with ID: ", docRef.id);\n})\n.catch(function(error) {\n  console.error("Error adding document: ", error);\n});
          \n// Add a second document with a generated ID.\ndb.collection("users").add({\nfirst: "Alan",\nmiddle: "Mathison",\n  last: "Turing",\n  born: 1912\n})\n.then(function(docRef) {\n  console.log("Document written with ID: ", docRef.id);\n})\n.catch(function(error) {\n  console.error("Error adding document: ", error);\n});
          </pre><br/>
          `,
        },
      },
      'section-4': {
        sectionId: 'section-4',
        left: {
          html: `<h2>Read data</h2>
          <p>You can now use the "get" method to retrieve an entire collection.
          </p>
          <br/>
          <p> The \`.get()\` method returns a promise that is passed a querySnapshot array.
          </p>
          <br/>
          <p>
          To access the individual documents in the collection, loop over the querySnapshot and call \`.data()\` on each doc object.</p>`,
        },
        right: {
          html: `
          <pre class='ql-syntax' spellcheck='false'>db.collection("users").get().then((querySnapshot) => {\n  querySnapshot.forEach((doc) => {\n    console.log(\`\${doc.id} => \${doc.data()}\`);\n  });\n});
          </pre>

          `,
        },
      },
      'section-5': {
        sectionId: 'section-5',
        left: {
          html: `<h2>More information</h2>
          <p><a href='https://firebase.google.com/docs/firestore/quickstart'>https://firebase.google.com/docs/firestore/quickstart</a></p>`,
        },
        right: {
          html: ``,
        },
      },
    },
  },
};

// export const exampleNote2:INote = {
//   noteId: 'exampleNote1',
//   ownerIds: [''],
//   title: 'Firestore API Example',
//   inTrash: false,
//   default: true,
//   createdAt: {} as firebase.firestore.Timestamp,
//   updatedAt: {} as firebase.firestore.Timestamp,
//   sectionsOrder: ['section-1', 'section-2', 'section-3'],
//   sections: {
//     'section-1': {
//       id: 'section-1',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1', 'module-2'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Setup Development Environment
//             \nAfter setting up the app's Firebase project on the Firebase website, add the Cloud Firestore library to the app. Install the \`firebase\` package using \`npm\` and import the module into your main app file.`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'code',
//           payloadObj: {
//             code: `//in root directory\nnpm install firebase@5.8.0 --save`,
//             settings: {
//               languageLabel: 'Markdown',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//         'module-2': {
//           id: 'module-2',
//           type: 'code',
//           payloadObj: {
//             code: `//in your app or config file\nconst firebase = require("firebase");\n// Required for side-effects\nrequire("firebase/firestore");`,
//             settings: {
//               languageLabel: 'Javascript',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-2': {
//       id: 'section-2',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Initialize Cloud Firestore
//             \nNext, initialize an instance of Cloud Firestore.
//             \nYou'll need to insert the \`apiKey\`, \`authDomain\`, and \`projectId\` found on the app's Firebase project website.`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'code',
//           payloadObj: {
//             code: `// Initialize Cloud Firestore through Firebase\nfirebase.initializeApp({\n  apiKey: '### FIREBASE API KEY ###',\n  authDomain: '### FIREBASE AUTH DOMAIN ###',\n  projectId: '### CLOUD FIRESTORE PROJECT ID ###'\n});\n\nvar db = firebase.firestore();`,
//             settings: {
//               languageLabel: 'Javascript',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-3': {
//       id: 'section-3',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1', 'module-2'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Add data
//             \nCloud Firestore stores data in Documents, which are stored in Collections. Cloud Firestore creates collections and documents implicitly the first time you add data to the document. You do not need to explicitly create collections or documents.
//             \nCreate a new collection and a document using the following example code.
//             \nNow add another document to the users collection. Notice that this document includes a key-value pair (middle name) that does not appear in the first document. Documents in a collection can contain different sets of information.`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'code',
//           payloadObj: {
//             code: `db.collection("users").add({\n  first: "Ada",\n  last: "Lovelace",\n  born: 1815\n})\n.then(function(docRef) {\n  console.log("Document written with ID: ", docRef.id);\n})\n.catch(function(error) {\n  console.error("Error adding document: ", error);\n});`,
//             settings: {
//               languageLabel: 'Javascript',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//         'module-2': {
//           id: 'module-2',
//           type: 'code',
//           payloadObj: {
//             code: `// Add a second document with a generated ID.\ndb.collection("users").add({\nfirst: "Alan",\nmiddle: "Mathison",\n  last: "Turing",\n  born: 1912\n})\n.then(function(docRef) {\n  console.log("Document written with ID: ", docRef.id);\n})\n.catch(function(error) {\n  console.error("Error adding document: ", error);\n});`,
//             settings: {
//               languageLabel: 'Javascript',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-4': {
//       id: 'section-4',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## Read data
//             \nYou can now use the "get" method to retrieve an entire collection. The \`.get()\` method returns a promise that is passed a querySnapshot array.
//             \n\nTo access the individual documents in the collection, loop over the querySnapshot and call \`.data()\` on each doc object.`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'code',
//           payloadObj: {
//             code: `db.collection("users").get().then((querySnapshot) => {\n  querySnapshot.forEach((doc) => {\n    console.log(\`\${doc.id} => \${doc.data()}\`);\n  });\n});`,
//             settings: {
//               languageLabel: 'Javascript',
//               wrapCode: true,
//               theme: '',
//               showLineNumbers: true,
//               readOnly: false,
//               tabSize: 2,
//               fontSize: 14,
//               enableLiveAutocompletion: false,
//               linterOn: false,
//             },
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//     'section-5': {
//       id: 'section-5',
//       leftModulesOrder: ['module-1'],
//       rightModulesOrder: ['module-1'],
//       leftModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'markdown',
//           payloadObj: {
//             markdown: `## More information`,
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             leftModulesId: '',
//           },
//         },
//       },
//       rightModules: {
//         'module-1': {
//           id: 'module-1',
//           type: 'link',
//           payloadObj: {
//             link: `https://firebase.google.com/docs/firestore/quickstart`,
//             linkType: 'Preview',
//           },
//           moduleAddress: {
//             noteId: '',
//             sectionId: '',
//             rightModulesId: '',
//           },
//         },
//       },
//     },
//   },
// };
