import TurndownService from 'turndown';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { INotesObj } from '../store/reducers';
import { IEditorState } from '../components/AlignedEditor';

const combineEditorStateHtml = (editorState: IEditorState) => {
  let html = '';
  editorState.sectionsOrder.map(sectionId => {
    html = html + editorState.sections[sectionId].left.html;
    html = html + editorState.sections[sectionId].right.html;
  });
  return html;
};

export const htmlToMarkdown = (html: string): string => {
  const turndownService = new TurndownService();
  const markdown: string = turndownService.turndown(html);
  return markdown;
};

const editorStateToMarkdown = (editorState: IEditorState) => {
  const html = combineEditorStateHtml(editorState);
  return htmlToMarkdown(html);
};

const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

const createZipFromNotes = (notes: INotesObj) => {
  let zip = new JSZip();
  Object.keys(notes).map(key => {
    const note = notes[key];
    if (!note.inTrash && !note.default) {
      const fileTitle = slugify(
        note.title +
          ' ' +
          moment(moment.unix(note.updatedAt.seconds)).format(
            'YYYY-MM-DD, HH:mm:ss'
          )
      );

      const markdown = editorStateToMarkdown(note.editorState);

      zip.file(`${fileTitle}.md`, markdown);
    }
  });
  return zip;
};

export const exportNotes = async (notes: INotesObj) => {
  const zip = createZipFromNotes(notes);
  zip.generateAsync({ type: 'blob' }).then(function(blob) {
    saveAs(blob, 'wingnotes-export.zip');
  });
};
