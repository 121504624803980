import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { createNote, setUpNewUser } from '../../store/actions';

import { IAppState } from '../../store/reducers';
import { styled } from '../common/ThemedStyledComponent';

import { INote, IAuthResult } from '../../data-models';
import { Layout } from '../website/Layout';
import LoginForm from './LoginForm';

interface IDispatchProps {
  setUpNewUser(newUser: any): void;
  createNote(newNote: INote): void;
}
interface ICredentials {
  email: string;
  password: string;
}

interface IState extends ICredentials {}
interface IProps extends IDispatchProps {
  authError: string;
}

class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  public render() {
    return (
      <Layout>
        <LoginView>
          <LoginForm label='Login'/>
        </LoginView>
      </Layout>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    authError: state.authStore.authError,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setUpNewUser: bindActionCreators(setUpNewUser, dispatch),
    createNote: bindActionCreators(createNote, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

const LoginView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const FormStyled = styled.form`
  height: fit-content;
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 5px 50px -5px #e4e4e4;
  padding: 40px;
`;
const Input = styled.input`
  box-sizing: border-box;
  font-size: inherit;
  border: none;
  padding: 11px;
  outline: none;
  background: transparent;
  color: inherit;
  font-weight: 600;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 4px;
  width: 100%;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a7a7a7;
    opacity: 1;
  }
`;
