import React from 'react';
import { styled } from '../common/ThemedStyledComponent';
import ContentLoader from 'react-content-loader';

interface IProps {}

const NoteLoader = ({  }: IProps) => {
  return (
    <LoaderSectionStyled>
      <LeftSectionWrapper>
        <LeftLoader />
      </LeftSectionWrapper>
      <RightSectionWrapper>
        <RightLoader />
      </RightSectionWrapper>
    </LoaderSectionStyled>
  );
};

export { NoteLoader };

const LoaderSectionStyled = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10%;
`;

const LeftSectionWrapper = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  padding: 5%;
`;

const RightSectionWrapper = styled.div`
  max-width: 50%;
  flex-basis: 50%;
  padding: 5%;
`;

const LeftLoader = (props: any) => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
    <rect x="79" y="0" rx="3" ry="3" width="100" height="10" />
    <rect x="188.32" y="0" rx="3" ry="3" width="24.3" height="10" />
    <rect x="220.36" y="0" rx="3" ry="3" width="110.4" height="10" />
    <rect x="341.12" y="0" rx="3" ry="3" width="30" height="10" />
    <rect x="298.43" y="20" rx="3" ry="3" width="84" height="10" />
    <rect x="1" y="20" rx="3" ry="3" width="141.7" height="10" />
    <rect x="155" y="20" rx="3" ry="3" width="130" height="10" />
    <rect x="1.1" y="40" rx="3" ry="3" width="103.5" height="10" />
    <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
    <rect x="185" y="40" rx="3" ry="3" width="60" height="10" />
    <rect x="253" y="40" rx="3" ry="3" width="100" height="10" />
    <rect x="42.36" y="60" rx="3" ry="3" width="110.4" height="10" />
    <rect x="1" y="60" rx="3" ry="3" width="30" height="10" />
  </ContentLoader>
);

const RightLoader = (props: any) => (
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    // dark color: #011627
    primaryColor="rgba(237,230,230,1)"
    secondaryColor="#fff"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="3" width="70" height="10" />
    <rect x="80" y="0" rx="3" ry="3" width="100" height="10" />
    <rect x="190" y="0" rx="3" ry="3" width="50" height="10" />
    <rect x="15" y="20" rx="3" ry="3" width="130" height="10" />
    <rect x="155" y="20" rx="3" ry="3" width="46" height="10" />
    <rect x="210" y="20" rx="3" ry="3" width="149.5" height="10" />
    <rect x="15" y="40" rx="3" ry="3" width="90" height="10" />
    <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
    <rect x="185" y="40" rx="3" ry="3" width="84.6" height="10" />
    <rect x="0" y="60" rx="3" ry="3" width="30" height="10" />
  </ContentLoader>
);
