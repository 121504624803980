// Theme.tsx
import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  light: {
    color: {
      lightShade: 'rgba(233, 230, 237, .75)',
      lightAccent: 'rgba(233, 230, 237, 1)',
      mainBrand: '#876351' /* Shadow */,
      darkShade: '#252423' /* Shark */,
      darkAccent: '#ac7742' /* Cape Palliser */,
      primary: '#876351' /* Shadow */,
      info: '#232525' /* Shark */,
      success: '#5e9850' /* Fruit Salad */,
      warning: '#db8818' /* Zest */,
      danger: '#f44336' /* Pomegranate */,
    },
    borderRadius: '6px'
  },
};

export type Theme = typeof theme;

export const styled = baseStyled as ThemedStyledInterface<Theme>;
