import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { INote, ISections } from '../data-models';

const firestore = firebase.firestore;

export const defaultNewNote: INote = {
  noteId: 'default',
  ownerIds: [''],
  title: '',
  tags: [],
  inTrash: false,
  default: true,
  createdAt: {} as firebase.firestore.Timestamp,
  updatedAt: {} as firebase.firestore.Timestamp,
  editorState: {
    sectionsOrder: ['section-1'],
    sections: {
      'section-1': {
        sectionId: 'section-1',
        left: {
          html: ''
        },
        right: {
          html: ''
        },
      },
    },
  },
};
