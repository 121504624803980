import React, { useState, useEffect, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators, compose } from 'redux';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
// @ts-ignore
import TextLoop from 'react-text-loop';
// @ts-ignore
import Parallax from 'react-rellax';
// @ts-ignore
import TextareaAutosize from 'react-textarea-autosize';

import { styled } from '../common/ThemedStyledComponent';
import { Layout } from './Layout';

import { ISections, INote } from '../../data-models';
import {
  // updateSectionOrder,
  setUpNewUser,
  createNote,
} from '../../store/actions';
import { IAppState, INotesObj } from '../../store/reducers';
import { Link } from 'react-router-dom';
import LoginForm from '../auth/LoginForm';
import { AlignedEditor } from '../AlignedEditor';
import { SelectorMenu } from '../common/SelectorMenu';
import { DemoEditor } from './DemoEditor';

interface IState {
  title: string;
  currentNote: string;
  notes: INotesObj;
  isLoading: boolean;
}

interface IProps {
  notes: INotesObj;
  setUpNewUser(newUser: any): void;
  createNote(newNote: INote): void;
}

class Examples extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { notes } = this.props;
    this.state = {
      title: notes['algorithms'].title,
      currentNote: 'algorithms',
      notes,
      isLoading: false,
    };
  }
  render() {
    const { currentNote, title, notes, isLoading } = this.state;
    // const { notes } = this.props;

    return (
      <Layout>
        <HeroContainer>
          <HeroCenter>
            <h1>Examples</h1>
            <SelectorMenu
              items={['Algorithms', 'Firestore', 'Global Optimization']}
              defaultValue="Algorithms"
              isLoading={false}
              onChange={this.handleSelectedNoteChange}
              layout="compact"
              orientation="horizontal"
            />
          </HeroCenter>
        </HeroContainer>
        <ContentContainer>
          <NoteDemo>
            <div css={smallScreenWarningStyles}>
              <strong>Heads up:</strong> although WingNotes is still useable at
              your current screen size, it's best viewed using a desktop browser
              instead!
            </div>
            <NoteDemoHeader>
              {/* <Input
                value={title}
                onChange={event => {
                  setTitle(event.target.value);
                }}
              /> */}
              <StyledTextAreaAutosize
                value={title}
                onChange={this.handleTitleChange}
                placeholder="Title"
              />
            </NoteDemoHeader>
            <DemoEditor
              editorState={this.props.notes[currentNote].editorState}
              isLoading={isLoading}
            />
          </NoteDemo>
          <FooterCTA>
            <LoginForm label="Use WingNotes for free" />
          </FooterCTA>
        </ContentContainer>
      </Layout>
    );
  }

  private handleSelectedNoteChange = (value: string) => {

    const selectedNote = value.toLowerCase();
    this.setState(
      {
        title: this.state.notes[selectedNote].title,
        currentNote: value.toLowerCase(),
        isLoading: true,
      },
      () =>
        this.setState({
          isLoading: false,
        })
    );
  };

  private handleTitleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    this.setState({
      title: event.target.value,
    });
  };
}

// Lazy to create a HomeContainer file.
function mapStateToProps(state: IAppState, ownProps: any) {
  return {
    notes: state.notesStore.defaultNotes,
  };
}

// FUTURE: Make SectionsList handle updateSectionsOrder on its own
function mapDispatchToProps(dispatch: Dispatch<any>): any {
  return {
    setUpNewUser: bindActionCreators(setUpNewUser, dispatch),
    createNote: bindActionCreators(createNote, dispatch),
  };
}

const ConnectedExamples = connect(
  mapStateToProps,
  mapDispatchToProps
)(Examples as any);

export { ConnectedExamples as Examples };

const HeroContainer = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 40px auto 30px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 15vh;
  justify-content: center;
  /* background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url('../hero_banner.svg'); */
  /* background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center; */
`;
const HeroCenter = styled.div`
  width: 100%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 14px;
  }
  @media (max-width: 1140px) {
    margin: 150px 2% 125px;
  }
  @media (max-width: 960px) {
    margin: 150px 4% 125px;
  }
`;

const HeroCTA = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 550px) {
    margin-top: 5px;
  }
`;

const exampleSelectorStyles = css`
  display: flex;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  /* margin-bottom: -380px; */
`;

const NoteDemoHeader = styled.div`
  padding: 0px 4% 0px 5.5%;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 720px) {
    padding: 0px 4% 0px 7.5%;
  }
  @media (max-width: 600px) {
    padding: 0px 4% 0px 8.5%;
  }
  @media (max-width: 500px) {
    padding: 0px 4% 0px 9.5%;
  }
  @media (max-width: 400px) {
    padding: 0px 4% 0px 10.5%;
  }
  @media (max-width: 350px) {
    padding: 0px 4% 0px 11.5%;
  }
`;

const NoteDemoEditor = styled.div`
  padding: 0px 4% 0px 4.5%;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 720px) {
    padding: 0px 4% 0px 5.5%;
  }
  @media (max-width: 600px) {
    padding: 0px 4% 0px 6.5%;
  }
  @media (max-width: 500px) {
    padding: 0px 4% 0px 7.5%;
  }
  @media (max-width: 400px) {
    padding: 0px 4% 0px 8.5%;
  }
  @media (max-width: 350px) {
    padding: 0px 4% 0px 9.5%;
  }
`;

const StyledTextAreaAutosize = styled(TextareaAutosize)`
  border: 0px;
  background-color: transparent;
  padding: 4px 0px 4px 4px;
  font-family: 'Inter UI', Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 600;
  resize: none;
  overflow-wrap: break-word;
  width: 100%;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;

const NoteDemo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 7px;
  z-index: 10;
  background: white;
  box-shadow: 0px 5px 50px -5px #e4e4e4;
  padding: 70px 0px 30px;
  margin: 0px auto 150px;
`;

const FooterCTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto 40px;
`;

const smallScreenWarningStyles = css`
  display: none;
  margin: -20px 4% 10px 4.5%;
  padding: 0px 6px;
  width: auto;
  box-sizing: border-box;
  background: #ffb6b6;
  border-radius: 4px;
  @media (max-width: 768px) {
    display: unset;
  }
  /* @media (max-width: 720px) {
    margin: 0px 4% 10px 7.5%;
  }
  @media (max-width: 600px) {
    margin: 0px 4% 10px 8.5%;
  }
  @media (max-width: 500px) {
    margin: 0px 4% 10px 9.5%;
  }
  @media (max-width: 400px) {
    margin: 0px 4% 10px 10.5%;
  }
  @media (max-width: 350px) {
    margin: 0px 4% 10px 11.5%;
  } */
`;
