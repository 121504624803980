import React from 'react';
import { styled } from '../common/ThemedStyledComponent';
// @ts-ignore
import TextareaAutosize from 'react-textarea-autosize';

interface IProps {
  title: string;
  onChange: (event: any) => void;
}

export const NoteHeader = (props: IProps) => {
  const { title, onChange } = props;
  return (
    <NoteHeaderWrapper>
      <StyledTextAreaAutosize
        value={title}
        onChange={(e: any) => onChange(e)}
        placeholder="Title"
      />
    </NoteHeaderWrapper>
  );
};

const NoteHeaderWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-height: fit-content;
  margin: 12vh auto 0;
  padding-left: 8px;
`;

const StyledTextAreaAutosize = styled(TextareaAutosize)`
  border: 0px;
  background-color: transparent;
  padding: 4px 0px 4px 4px;
  font-size: 2em;
  font-weight: 600;
  resize: none;
  overflow-wrap: break-word;
  width: 100%;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #f8f8f8;
  }
`;
