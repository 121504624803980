//authentication checker (guard) to redirect non-signed-in users to /login or /signup
//if sign-in and authenticated, allow them to access authorized page

import React, { Component } from 'react';
import { IAppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { styled } from '../common/ThemedStyledComponent';
//@ts-ignore
import Loader from 'react-loader-spinner';

interface IHOCProps {
  auth: { isLoaded: boolean; uid: string };
}

//React.ComponentType<IComponentProps> is an alias for React.StatelessComponent<IComponentProps> | React.ClassComponent<IComponentProps>, allowing for sclass and functional components.
export const AuthGuardHOC =  <IComponentProps extends {}> (
  InputComponent: React.ComponentType<IComponentProps>
) => {
  //IComponentProps and IHOCProps combined via type intersection operator (&)
  class AuthGuard extends Component<IComponentProps & IHOCProps> {
    public render() {
      const { auth } = this.props;

      if (!auth.isLoaded) {
        //FUTURE: place loader here
        return (
          <LoaderView>
            <Loader type="Puff" color="#e9e6e3" height="100" width="100" />
        </LoaderView>

        );
      } else if (auth.isLoaded && !auth.uid) {
        return <Redirect to="/login" />;
      } else {
        return <InputComponent {...this.props} />;
      }
    }
  }

  const mapStateToProps = (state: IAppState) => {
    return {
      auth: state.firebase.auth,
    };
  };

  return connect(mapStateToProps)(AuthGuard as any);
}

const LoaderView = styled.div`
  height: 100vh;
  /* makes the body non-scrollable */
  overflow: hidden;
  margin: 0px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;
