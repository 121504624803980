import * as React from 'react';
import { Fragment, useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import InsertSectionButton from './InsertSectionButton';
import SectionSettingsButton from './SectionSettingsButton';

//@ts-ignore
import styled from '@emotion/styled/macro';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ISection } from '../../data-models';
import Left from './Left';
import { Right } from './Right';
import { createBlankSection } from './Templates/newSection';
import dragHandleIcon from './dragHandleIcon.svg';

interface IProps {
  section: ISection;
  sectionsLength: number;
  sectionId: string;
  sectionIndex: number;
  collapsed: boolean;
  readOnly?: boolean;
  insertSection: (sectionIndex: number, sectionToInsert: ISection) => void;
  deleteSection: (sectionId: string) => void;
}

const DragHandle = SortableHandle(() => (
  <div css={dragHandleStyles}>
    <img src={dragHandleIcon} width="24" height="24" />
  </div>
));

const SortableItem = SortableElement(
  ({
    children,
    onSelect,
    sectionsLength,
    collapsed,
    readOnly,
  }: {
    children: any;
    onSelect: any;
    sectionsLength: number;
    collapsed: boolean;
    readOnly?: boolean;
  }) => (
    <StyledFullWidthContainer readOnly={readOnly}>
      {/* // Side Buttons */}
      {readOnly ? null : (
        <StyledSideButtons>
          <SectionSettingsButton
            onSelect={onSelect}
            sectionsLength={sectionsLength}
            collapsed={collapsed}
          />
          <div css={dragHandleIconStyles}>
            <DragHandle />
          </div>
        </StyledSideButtons>
      )}

      {children}
    </StyledFullWidthContainer>
  )
);

const Section = (props: IProps) => {
  const {
    section,
    sectionId,
    sectionsLength,
    sectionIndex,
    insertSection,
    deleteSection,
    readOnly,
  } = props;
  const { left, right } = section;
  const collapsedProp = useRef(props.collapsed);

  const [collapsed, setCollapsed] = useState(props.collapsed);

  useEffect(() => {
    if (collapsedProp.current !== props.collapsed) {
      setCollapsed(props.collapsed);
      collapsedProp.current = props.collapsed;
    }
  });

  const handleInsertSectionClick = (e: any, first?: boolean) => {
    const section = createBlankSection();

    if (first) {
      insertSection(-1, Object.assign({}, section));
    } else {
      insertSection(sectionIndex, Object.assign({}, section));
    }
  };

  const handleSectionSettingsSelect = (eventKey: string, data?: any) => {
    if (eventKey === 'delete') {
      deleteSection(sectionId);
    } else if (eventKey === 'collapse') {
      setCollapsed(!collapsed);
    }
    // else if (eventKey === 'duplicate') {
    //   // scrapped bc of complexity of changing moduleIds for all modules of a section
    //   const leftModuleIds = section.leftModulesOrder;
    //   const rightModuleIds = section.rightModulesOrder;

    //   const modules = {};
    //   insertSection(postId, sectionIndex, section);
    // }
  };

  return (
    <Fragment>
      {/* First Insert Section button of a note */}
      {sectionIndex === 0 && !readOnly ? (
        <InsertSectionButton onClick={handleInsertSectionClick} first={true} />
      ) : null}

      {/* Draggable Section that contains Section Settings Button, Left, and Right Modules */}
      <SortableItem
        key={`item-${sectionIndex}`}
        index={sectionIndex}
        disabled={readOnly}
        readOnly={readOnly}
        onSelect={handleSectionSettingsSelect}
        sectionsLength={sectionsLength}
        collapsed={collapsed}
      >
        <div css={max1200pxContainerStyles}>
          <div css={SectionMiddleWrapper}>
            <div css={leftAndRightContainerStyles}>
              <Left
                collapsed={collapsed}
                sectionId={sectionId}
                left={left}
              />
              <Right
                collapsed={collapsed}
                sectionId={sectionId}
                right={right}
              />
            </div>
          </div>
        </div>
      </SortableItem>

      {/* Insert Section Button after each section */}
      {readOnly ? null : (
        <InsertSectionButton onClick={handleInsertSectionClick} />
      )}
    </Fragment>
  );
};

export default connect(state => state)(Section);

const StyledSideButtons = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 0px;
  opacity: 0;
  transition: all 0.4s;
  position: relative;
  top: 7px;
  right: 1.2em;
  @media (max-width: 400px) {
    padding-left: 1%;
  }
`;

// div styled like transprent button bc Button is unable to be dragged
const dragHandleIconStyles = css`
  justify-content: space-between;
  align-items: center;
  display: flex;
  border-color: transparent;
  background-color: transparent;
  width: max-content;
  height: fit-content;
  border-radius: 2px;
  box-shadow: none;
  &:hover {
    background-color: rgba(233, 230, 237, 1);
    border-color: transparent;
  }
  &:active {
    color: inherit;
    background-color: #e9e6e3;
  }
  &:focus {
    outline: none;
    background-color: rgba(233, 230, 237, 1);
    box-shadow: none;
  }
`;

const SectionMiddleWrapper = css`
  width: 100%;
  display: flex;
  padding: 6px 0px;
`;

const leftAndRightContainerStyles = css`
  width: 100%;
  display: flex;
  position: relative;
  /* media queries */
  @media (max-width: 769px) {
    flex-direction: column;
    align-items: center;
  }
`;

interface ISectionStyleProps {
  readOnly?: boolean;
}

const StyledFullWidthContainer = styled('div')<ISectionStyleProps>`
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  max-height: fit-content;
  min-height: 200px;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: none;
  border-radius: 4px;
  margin-bottom: ${(props: ISectionStyleProps) =>
    props.readOnly ? '40px' : 'unset'};
  &:hover {
    background: ${(props: ISectionStyleProps) =>
      props.readOnly ? 'inherit' : '#f3f3f3'};
  }
  /* //FUTURE: fix so that hovering over FullWidthContainer of insertSectionButton reveals button */
  &:hover ${StyledSideButtons} {
    opacity: 1;
    transition: all 0.4s;
  }
  .sortableHelper {
    background: #f3f3f3;
    box-shadow: -2px 4px 12px 1px rgba(0, 0, 0, 0.125);
  }
  /* @media (max-width: 1134px) {
    padding: 0px 10px 0px calc(3% + 10px);
  }
  @media (max-width: 780px) {
    padding: 0px 15px 0px calc(4.25% + 12px);
  } */
`;

const max1200pxContainerStyles = css`
  display: flex;
  width: 100%;
  max-width: 1200px;
  max-height: fit-content;
`;

const dragHandleStyles = css`
  cursor: grab;
`;
