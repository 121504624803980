import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { createNote, setUpNewUser } from '../../store/actions';

import { IAppState } from '../../store/reducers';
import { styled } from '../common/ThemedStyledComponent';
import { Link } from 'react-router-dom';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { firebase } from '../../config/fbConfig';

import { INote, IAuthResult } from '../../data-models';
import { Layout } from '../website/Layout';
import { Mixpanel } from '../../helpers/Mixpanel';

interface IDispatchProps {
  setUpNewUser(newUser: any): void;
}

interface IProps extends IDispatchProps {
  label: string;
}

const LoginForm = (props: IProps) => {
  const { label, setUpNewUser } = props;

  // Configure FirebaseUI. Had to move from fbconfig bc needed to use dispatch for bindactioncreators on createNote function
  const uiConfig = {
    // Popup login flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/notes/redirect',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // for email link; need to address ui.isPendingRedirect() requirement
        // signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (
        authResult: IAuthResult,
        redirectUrl: string
      ) => {
        if (authResult.additionalUserInfo.isNewUser) {
          setUpNewUser(authResult.user);
          Mixpanel.identify(authResult.user.email);
          Mixpanel.track('Signed Up Successfully');
          Mixpanel.people.set({
            $email: authResult.user.email,
            $name: authResult.user.displayName,
            $created: authResult.user.metadata.creationTime,
          });
        }
        Mixpanel.identify(authResult.user.email);
        Mixpanel.track('Logged In Successfully');
        return false;
      },
    },
  };

  return (
    <FormStyled onSubmit={() => {}}>
      <h1>{label}</h1>
      <p style={{ color: 'grey' }}>(New accounts auto-created)</p>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </FormStyled>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setUpNewUser: bindActionCreators(setUpNewUser, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

const FormStyled = styled.form`
  height: fit-content;
  /* width: 240px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 5px 50px -5px #e4e4e4;
  padding: 40px;
  margin: 0px 20px 100px;
  h1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  .firebaseui-idp-password {
    background: #4e42ff;
    &:hover {
      color: inherit;
      background: #4e42ff;
    }
    &:active {
      color: inherit;
      background: #4e42ff;
    }
    &:focus {
      color: inherit;
      background: #4e42ff;
    }
  }
  @media (max-width: 365px) {
    padding: 40px 15px;
  }
`;
