import * as React from 'react';

import { connect } from 'react-redux';
import { ISections } from '../../data-models';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { SortableContainer } from 'react-sortable-hoc';
import SectionWithContext from './SectionWithContext';

interface IProps {
  sections: ISections;
  sectionsOrder: string[];
  updateSectionOrder: (oldIndex: number, newIndex: number) => void;
}

const SortableContainerWrapper = SortableContainer(
  ({ children }: { children: any }) => {
    return <div css={sectionsListStyles}>{children}</div>;
  }
);

const SectionsList = (props: IProps) => {
  const { sections, sectionsOrder } = props;

  const renderSections = () => {
    return sectionsOrder.map((sectionId, index) => {
      return (
        <SectionWithContext
          key={sectionId}
          sectionIndex={index}
          sectionId={sectionId}
          section={(sections as ISections)[sectionId]}
          sectionsLength={sectionsOrder.length}
        />
      );
    });
  };

  const onDragEnd = (result: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = result;
    const { updateSectionOrder } = props;
    if (newIndex === null) {
      return;
    }
    if (oldIndex === newIndex) {
      return;
    }
    updateSectionOrder(oldIndex, newIndex);
    return;
  };

  return (
    <SortableContainerWrapper
      onSortEnd={onDragEnd}
      useDragHandle
      lockAxis={'y'}
      helperClass={'sortableHelper'}
    >
      {renderSections()}
    </SortableContainerWrapper>
  );
};

export default (SectionsList);

const sectionsListStyles = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: max-content;
  margin-bottom: 20px;
`;
