import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { IAppState } from '../../../store/reducers/index';
import { Toolbar } from './Toolbar';
import { saveCurrentNote, moveNoteToTrash } from '../../../store/actions';

interface IStateToProps extends IOwnProps{
   hasChanges: boolean;
}
interface IDispatchToProps {
   moveNoteToTrash(noteId: string):any;
   saveCurrentNote(noteId: string):void;
}

interface IOwnProps {
   urlNoteId: string;
}

export interface IToolbarProps extends IStateToProps, IDispatchToProps {}

function mapStateToProps(state: IAppState, ownProps: IOwnProps): IStateToProps {
   return {
      urlNoteId: ownProps.urlNoteId,
      hasChanges: state.notesStore.currentNoteStatus.hasChanges
   };
}

//FUTURE: fix :any typing
function mapDispatchToProps(dispatch: Dispatch<any>): any {
   return {
      moveNoteToTrash: bindActionCreators(moveNoteToTrash, dispatch),
      saveCurrentNote: bindActionCreators(saveCurrentNote, dispatch),
   };
}

//FUTURE: fix as any
export const ToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(Toolbar) as any;
