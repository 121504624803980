import * as React from 'react';
import { connect } from 'react-redux';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { IEditorJsData } from '.';
import TextEditor from './Modules/TextEditor/';
import { ISideData } from '../../data-models';

interface IState {}

interface IProps {
  sectionId: string;
  left: ISideData;
  children?: React.ReactNode;
  collapsed: boolean;
  insertSection?: (noteId?: string, sectionIndex?: number) => void;
  deleteSection?: (noteId?: string, sectionIndex?: number) => void;
}

const Left = (props: IProps) => {
  const { sectionId, left } = props;
  return (
    <div css={leftViewStyles}>
      {/* <AtlasKitSimple/> */}
      {/* <AtlasKitEditor /> */}
      {/* <EditorJs
        sectionId={sectionId}
        leftOrRight="left"
        editorData={leftData}
      /> */}
      <TextEditor
        sectionId={sectionId}
        leftOrRight="left"
        editorData={left}
      />
      {props.children}
    </div>
  );
};

export default connect(state => state)(Left);

//styles
const leftViewStyles = css`
  /* for sizing */
  flex: 0 0 60%;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 769px) {
    padding-right: unset;
    border-right: unset;
    flex: 1.5 1 auto;
  }
  .codex-editor__redactor {
    padding: unset;
  }
`;
