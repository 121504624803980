import { NoteAction, UserAction, UtilityAction } from '../constants';
import { Dispatch } from 'redux';
import { INote, ISection } from '../../data-models';
import { IAppState, INotesObj } from '../reducers';
import { createNoteTemplate } from '../../templates';
import { NetworkProvider } from '../NetworkProvider';
import { Mixpanel } from '../../helpers/Mixpanel';
import { IEditorState } from '../../components/AlignedEditor';

// ------------ NOTES ------------

//synchronous action creators, used in async functions below

// const isCurrentNoteDownloaded = (isCurrentNoteDownloaded: boolean) => {
//   return { type: NoteAction.isCurrentNoteDownloaded, isCurrentNoteDownloaded };
// };

//to trigger redirect on new sign in, new note created, invalid note url, and moveToTrash
const redirect = (redirect: boolean) => {
  return { type: NoteAction.redirect, redirect };
};

//to trigger save button
const hasChanges = (hasChanges: boolean) => {
  return {
    type: NoteAction.hasChanges,
    hasChanges,
  };
};

const setNoteIdOnDisplay = (urlParamsId: string) => {
  return {
    type: NoteAction.setNoteIdOnDisplay,
    urlParamsId,
  };
};

export const setNoteUpdated = (noteId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    // const state: IAppState = getState();
    // Mixpanel.identify(state.firebase.auth.email);
    // Mixpanel.track('Permanently Deleted Post');
    return dispatch({
      type: NoteAction.setNoteUpdated,
      noteId,
    });
  };
};

const noteExistsinDB = (noteExistsInDB: boolean) => {
  return {
    type: NoteAction.noteExistsInDB,
    noteExistsInDB,
  };
};

//async functions
export const createNote = (newNote: INote) => {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;

    try {
      const newNoteId = await NetworkProvider.createNote(newNote);
      if (email) {
        Mixpanel.identify(email);
        Mixpanel.track('Created Note');
      }
      dispatch({
        type: NoteAction.createNote,
        newNoteId,
        newNote,
      });
      return newNoteId;
      // dispatch(redirect(true));
      // dispatch(redirect(false));
    } catch (err) {
      dispatch({ type: NoteAction.createNoteError, err });
    }
  };
};

//display the note from local storage
//download first if doesn't exist
export const displayNote = (urlParamsId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const userEmail: string = state.firebase.auth.email;
    if (!urlParamsId) {
      dispatch({
        type: NoteAction.downloadNoteError,
      });
      return;
    }
    try {
      const downloadedNote = await NetworkProvider.downloadNote(
        urlParamsId,
        userEmail
      );
      dispatch(noteExistsinDB(true));
      dispatch({
        type: NoteAction.downloadNoteSuccess,
        urlParamsId,
        downloadedNote,
      });
      dispatch(setNoteIdOnDisplay(urlParamsId));
      dispatch(hasChanges(false));
    } catch (error) {
      //if error, set noteExistsInDB to false?
      // dispatch(redirect(true));
      // dispatch(redirect(false));
      return;
    }
  };
};

// download N Notes
export const downloadNNotes = (num: number) => {
  return async (dispatch: Dispatch, getState: any) => {
    // dispatch(isCurrentNoteDownloaded(false));
    const state: IAppState = getState();
    const userEmail = state.firebase.auth.email;
    try {
      dispatch({ type: NoteAction.downloadNNotesLoading });
      const nNotes = await NetworkProvider.downloadNNotes(num, userEmail);
      dispatch({ type: NoteAction.downloadNNotesSuccess, nNotes });
      // dispatch(isCurrentNoteDownloaded(true));
    } catch (err) {
      dispatch({ type: NoteAction.downloadNNotesError, err });
    }
  };
};

export const saveCurrentNote = (noteId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const currentNote = state.notesStore.userNotes.notes[noteId];
    try {
      await NetworkProvider.uploadNote(noteId, currentNote);
      const email = state.firebase.auth.email;
      if (email) {
        Mixpanel.identify(email);
        Mixpanel.track('Saved Current Note');
      }
      dispatch(hasChanges(false));
    } catch (err) {
      throw err;
    }
  };
};

// update editorState to local storage only
export const updateEditorStateInStore = (
  noteId: string,
  editorState: IEditorState
) => {
  return (dispatch: any, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Updated Editor State');
    }
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.updateEditorStateInStore,
      noteId,
      editorState,
    });
    dispatch(setNoteUpdated(noteId));
  };
};

// update title in store
export const updateTitleInStore = (urlNoteId: string, updatedTitle: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const noteId = urlNoteId;
    dispatch(hasChanges(true));
    dispatch({
      type: NoteAction.updateTitleInStore,
      updatedTitle,
      noteId,
    });
  };
};

export const moveNoteToTrash = (noteId: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Moved Note to Trash');
    }
    dispatch({
      type: NoteAction.moveNoteToTrash,
      noteId,
    });
    //@ts-ignore: using dispatch to call saveCurrentNote allows note state to finish updating before calling saveCurrentNote
    dispatch(saveCurrentNote(noteId));
  };
};

export const restoreNoteFromTrash = (noteId: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Restored Note From Trash');
    }
    dispatch({
      type: NoteAction.restoreNoteFromTrash,
      noteId,
    });
    //@ts-ignore
    dispatch(saveCurrentNote(noteId));
  };
};

export const permDeleteNote = (noteId: string) => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Permanently Deleted Note');
    }
    await NetworkProvider.permDeleteNote(noteId);
    //FUTURE: fix redirect on permDeleteNote, currently does not redirect user to newest note
    // dispatch(redirect(true));
    // dispatch(redirect(false));
  };
};

// ------------ USER ------------

export const signOut = () => {
  return async (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Signed Out');
    }
    await NetworkProvider.signOut();
    window.localStorage.clear();
    dispatch({
      type: UserAction.signOutSuccess,
    });
  };
};

export const setUpNewUser = (newUser: any) => {
  return (dispatch: Function) => {
    const isDevelopment = String(process.env.NODE_ENV) === 'development';
    dispatch({ type: UserAction.signUpSuccess });
    // dispatch(
    //   createNote(createNoteTemplate(newUser.email, 'algorithmsExample'))
    // );
    // dispatch(createNote(createNoteTemplate(newUser.email, 'example2')));
    // dispatch(createNote(createNoteTemplate(newUser.email, 'meetingExample')));
    dispatch(createNote(createNoteTemplate(newUser.email)));
    dispatch({ type: UserAction.signUpSuccess });
    // dispatch({ type: UserAction.signUpError});
  };
};

export const updateSidebarCategory = (category: string) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: IAppState = getState();
    const email = state.firebase.auth.email;
    if (email) {
      Mixpanel.identify(email);
      Mixpanel.track('Updated Sidebar Category');
    }
    dispatch({
      type: UtilityAction.updateSidebarCategory,
      category,
    });
  };
};

export const toggleSidebarOrderLabel = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UtilityAction.toggleSidebarOrderLabel,
    });
  };
};

export const toggleSidebarAscOrDesc = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UtilityAction.toggleSidebarAscOrDesc,
    });
  };
};
