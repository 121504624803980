import React, { Component, Fragment } from 'react';
import { styled } from '../common/ThemedStyledComponent';

import { INote } from '../../data-models';
import { createNoteTemplate } from '../../templates';
import { ISidebarProps as IProps } from './SidebarContainer';
import * as Icon from 'react-feather';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import producer from 'immer';
import Button from 'react-bootstrap/Button';
import { NotesList } from './NotesList';
import { SidebarHeader } from './SidebarHeader';
import { SidebarFooter } from './SidebarFooter';

interface IState {
  settingsModalState: boolean;
  feedbackModalState: boolean;
  orderLabel: string;
  ascOrDesc: string;
  orderedNotes: INote[];
  category: string;
  sidebarShow: boolean;
  currentNoteId: string;
}

class Sidebar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { sidebarOrderLabel, sidebarAscOrDesc } = this.props;
    this.state = {
      settingsModalState: false,
      feedbackModalState: false,
      orderedNotes: orderBy(
        this.props.notes,
        sidebarOrderLabel,
        sidebarAscOrDesc as any
      ),
      category: 'All',
      sidebarShow: true,
      orderLabel: this.props.sidebarOrderLabel,
      ascOrDesc: this.props.sidebarAscOrDesc,
      currentNoteId: this.props.urlNoteId,
    };
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const {
      notes: prevNotes,
      sidebarOrderLabel: prevSidebarOrderLabel,
      sidebarAscOrDesc: prevSidebarAscOrDesc,
      category: prevCategory,
    } = prevProps;
    const {
      notes,
      sidebarOrderLabel,
      sidebarAscOrDesc,
      category,
      urlNoteId,
      history,
    } = this.props;

    // to redirect to new note
    if (urlNoteId !== this.state.currentNoteId) {
      history.push(`/notes/${this.state.currentNoteId}`);
    }

    if (
      !isEqual(prevNotes, notes) ||
      prevSidebarOrderLabel !== sidebarOrderLabel ||
      prevSidebarAscOrDesc !== sidebarAscOrDesc ||
      prevCategory !== category
    ) {
      this.setState(
        producer(this.state, draft => {
          draft.orderedNotes = orderBy(
            notes,
            sidebarOrderLabel,
            sidebarAscOrDesc as any
          );
          draft.orderLabel = sidebarOrderLabel;
          draft.ascOrDesc = sidebarAscOrDesc;
          draft.category = category;
          return;
        })
      );
    }
  }

  componentDidMount() {
    if (this.props.notes.length < 1) {
      this.props.downloadNNotes();
    }
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public render() {
    const { isLoading, email } = this.props;
    const {
      orderLabel,
      ascOrDesc,
      category,
      settingsModalState,
      feedbackModalState,
    } = this.state;

    const { notes, sidebarOrderLabel } = this.props;

    if (isLoading) {
      return null;
    }

    if (notes.length === 0) {
      return null;
    }

    return (
      <SidebarView sidebarShow={this.state.sidebarShow}>
        <Button
          //@ts-ignore: custom bootstrap class
          variant="transparent"
          onClick={(e: React.MouseEvent) => {
            this.toggleSidebar(e);
          }}
          onMouseDown={e => e.preventDefault()}
          style={{
            padding: '2px',
            width: 'fit-content',
            margin: '13px 6px',
            position: 'absolute',
            right: '-38px',
            zIndex: 1000,
          }}
        >
          <Icon.Sidebar size={20} color="#848484" />
        </Button>
        <Top sidebarShow={this.state.sidebarShow}>
          <SidebarHeader
            category={category}
            onNewNoteClick={this.handleNewNoteClick}
            updateSidebarCategory={this.props.updateSidebarCategory}
          />
          <NotesList
            toggleOrderLabel={this.toggleOrderLabel}
            toggleAscOrDesc={this.toggleAscOrDesc}
            orderLabel={orderLabel}
            ascOrDesc={ascOrDesc}
            notes={notes}
            category={category}
            updateSidebarCategory={this.props.updateSidebarCategory}
          />
        </Top>
        {/* <Middle sidebarShow={this.state.sidebarShow}>
        </Middle> */}
        <Bottom sidebarShow={this.state.sidebarShow}>
          <SidebarFooter
            email={email}
            settingsModalState={settingsModalState}
            feedbackModalState={feedbackModalState}
            onShowModalClick={this.showModal}
            onHideModalClick={this.closeModal}
            onSignOutClick={this.props.signOut}
            notes={this.props.notesObj}
          />
        </Bottom>
      </SidebarView>
    );
  }

  private handleNewNoteClick = async (e: any) => {
    e.preventDefault();
    const { email, createNote, saveCurrentNote, urlNoteId } = this.props;
    // createNoteTemplate(email);
    await saveCurrentNote(urlNoteId);
    const newNoteId = await createNote(createNoteTemplate(email));
    this.setState({
      currentNoteId: newNoteId,
    });
  };

  private showModal = (modal: string) => {
    if (modal === 'settings') {
      this.setState({ settingsModalState: true });
    } else if (modal === 'feedback') {
      this.setState({ feedbackModalState: true });
    }
  };

  private closeModal = () => {
    this.setState({ settingsModalState: false, feedbackModalState: false });
  };

  private toggleOrderLabel = () => {
    // const { orderLabel } = this.state;
    const { toggleSidebarOrderLabel } = this.props;
    toggleSidebarOrderLabel();
    // if (orderLabel === 'updatedAt') {
    //   this.setState({
    //     orderLabel: 'createdAt',
    //   });
    // } else {
    //   this.setState({
    //     orderLabel: 'updatedAt',
    //   });
    // }
  };

  private toggleAscOrDesc = () => {
    const { ascOrDesc } = this.state;
    const { toggleSidebarAscOrDesc } = this.props;
    toggleSidebarAscOrDesc();
    // if (ascOrDesc === 'asc') {
    //   this.setState({
    //     ascOrDesc: 'desc',
    //   });
    // } else {
    //   this.setState({
    //     ascOrDesc: 'asc',
    //   });
    // }
  };

  private toggleSidebar = (e: React.MouseEvent) => {
    e.preventDefault();
    const currentSidebarShowState = this.state.sidebarShow;
    this.setState({
      sidebarShow: !currentSidebarShowState,
    });
  };

  private handleResize = () => {
    if (window.innerWidth < 769) {
      this.setState({
        sidebarShow: false,
      });
    } else if (window.innerWidth >= 769) {
      this.setState({
        sidebarShow: true,
      });
    }
  };
}

export { Sidebar };

interface ISidebarViewProps {
  sidebarShow: boolean;
}

const SidebarView = styled.nav<ISidebarViewProps>`
  transform: ${props =>
    props.sidebarShow ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)'};
  width: ${props => (props.sidebarShow ? '225px' : '0px')};
  min-width: ${props => (props.sidebarShow ? '225px' : '0px')};
  max-width: ${props => (props.sidebarShow ? '225px' : '0px')};
  transition: all 0.25s;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fffcf5;
  justify-content: space-between;
  box-shadow: inset 2px 0px 25px -3px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  /* overflow-x: hidden;
  overflow-y: auto; */
  //allows both columns to span the full height of the browser window
  height: 100vh;
  &:after {
    visibility: hidden;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Top = styled.div<ISidebarViewProps>`
  visibility: ${props => (props.sidebarShow ? 'visible' : 'hidden')};
  opacity: ${props => (props.sidebarShow ? '1' : '0')};
  display: flex;
  transition: all 0.25s;
  flex-direction: column;
  height: calc(100vh - 48px);
`;

const Middle = styled.div<ISidebarViewProps>`
  visibility: ${props => (props.sidebarShow ? 'visible' : 'hidden')};
  opacity: ${props => (props.sidebarShow ? '1' : '0')};
  display: flex;
  transition: all 0.25s;
  flex-direction: column;
`;

const Bottom = styled.div<ISidebarViewProps>`
  visibility: ${props => (props.sidebarShow ? 'visible' : 'hidden')};
  opacity: ${props => (props.sidebarShow ? '1' : '0')};
  display: flex;
  transition: all 0.25s;
  padding: 8px 10px;
  align-items: center;
  justify-content: space-between;
  color: #848484;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;
