import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

import ReactQuill, { Quill } from 'react-quill';
//@ts-ignore
import * as MarkdownShortcuts from 'quill-markdown-shortcuts';
Quill.register('modules/markdownShortcuts', MarkdownShortcuts);
import './quill.bubble.css';
// import './quill.snow.css';

interface IProps {
  html: string;
  readOnly?: boolean;
  leftOrRight?: 'left' | 'right' | 'both';
  onChange: (updatedData: string) => void;
}

const QuillEditor = (props: IProps) => {
  const { readOnly, leftOrRight, onChange } = props;

  const [text, setText] = useState(props.html);
  const firstRender = useRef(0);

  useEffect(() => {
    // Guard clause bc first render triggers useEffect
    if (firstRender.current < 1) {
      firstRender.current++;
      return;
    }
    onChange(text);
    return;
  }, [text]);

  const toolbarOptions = [
    ['bold', 'italic', 'underline'], // toggled buttons
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['code-block'],
    ['link', 'image', 'video'],
    ['clean'], // remove formatting button
  ];

  const formatOptions = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'code-block',
  ];

  return (
    <ReactQuill
      defaultValue={text}
      onChange={(text: string, delta: any, source: string, editor: any) => {
        if (source == 'user') {
          setText(text);
        }
      }}
      theme="bubble"
      modules={{
        syntax: true,
        toolbar: toolbarOptions,
        markdownShortcuts: {},
      }}
      formats={formatOptions}
      placeholder={
        leftOrRight === 'right'
          ? 'Place your references here...'
          : 'Type your descriptions here...'
      }
      readOnly={readOnly}
    />
  );
};

export default QuillEditor;
