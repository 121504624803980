import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';

import { getFirestore, reduxFirestore } from 'redux-firestore';
import { getFirebase, reactReduxFirebase } from 'react-redux-firebase';
import { firebase as fbConfig } from './config/fbConfig';

import Router from './router';
import reducers from './store/reducers';

import './App.scss';
import { styled } from './components/common/ThemedStyledComponent';

import ReactGA from 'react-ga';

const firebaseAuthConfig = {
  useFirestoreForProfile: true,
  userProfile: 'users',
  attachAuthIsReady: true,
};

const isDevelopment = String(process.env.NODE_ENV) === 'development';
let composeEnhancers = compose;

if (isDevelopment) {
  composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      ReduxThunk.withExtraArgument({ getFirebase, getFirestore })
    ),
    reduxFirestore(fbConfig),
    reactReduxFirebase(fbConfig, firebaseAuthConfig)
  )
);

class App extends Component {
  constructor(props:{}) {
    super(props);
    ReactGA.initialize('UA-135933529-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {

    return (
      <Provider store={store}>
        <BrowserRouter>
          <AppStyled>
            <Router />
          </AppStyled>
        </BrowserRouter>
      </Provider>
    );
  }
}

export { App, store };

const AppStyled = styled.div`
  font-family: 'Inter UI', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 26px;
`;
